//-----for convert fit inch to cm---------
export const heightFtInToCm = (selectFt, selectIn) => {
  let cm = "";
  if (selectFt != "" || selectIn != "") {
    const totalInches = parseInt(selectFt) * 12 + parseInt(selectIn);
    cm = (totalInches * 2.54).toFixed(2);
  }
  return cm;
};

//----for convert lbs to kg------
export const weightLbsToKg = (lbs) => {
  return parseInt(lbs) * 0.45359237;
};






