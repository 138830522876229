import { Container, Form } from "react-bootstrap";
import styles from "../ForgotPassword/ForgotPassword.module.css";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/Images/black_Icon.png";
import "../../assets/Styles/Common.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../utils/Toaster";
import Swal from 'sweetalert2';
import { STATUS_CODES } from "../../utils/StatusCode";
import { DEVICE_WEBSITE } from "../../utils/Constants";
import { useEffect, useState } from "react";
import Loader from "../../utils/Loader/Loader";
import $ from "jquery";

// ---------function for forgot password ------------
function ForgotPassword() {
  const { t } = useTranslation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loader, setLoader] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  const passwordKeyPress = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, '')); // this remove from all string
  };

  // --------function for forgot password api calling-------------
  const onSubmit = async (formdata) => {
    setIsButtonDisabled(true);
    setLoader(true);
    await SublyApi.forgotPassword(formdata.email.trim(), DEVICE_WEBSITE.DEVICE_TYPE1).then((responsejson) => {
      if (responsejson && responsejson.settings.success == STATUS_CODES.SUCCESS_API) {
        if (!isButtonDisabled) {
          setLoader(false);
          setIsButtonDisabled(true);
          Swal.fire({
            title: 'Good job!',
            text: 'Reset link sent successfully on your email address.',
            icon: 'success',
            allowOutsideClick: false,
          }).then(() => {
            setIsButtonDisabled(false);
          });
        }
      }
      else {
        Toast.fire({
          icon: "error",
          title: responsejson && responsejson.settings.message,
        });
        setIsButtonDisabled(false);
        setLoader(false);
      }
    })
  };

  return (
    <>
      {loader ? (
        <div className="loader">
          <Loader />
        </div>
      ) : null}
      <div className="main">
        <section className={styles.resetPassword}>
          <Container>
            <div className={styles.FormBlock}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.FormSection}>
                  <div className={styles.SignupTitle}>
                    <NavLink to="/">
                      <img src={Logo} alt="logo" />
                    </NavLink>
                    <h2>{t("FORGOT_HEADING")}</h2>
                    <p>{t("FORGOT_PARA")}</p>
                  </div>
                  <div>
                    <Form.Group className={styles.FormGroup}>
                      <Form.Control
                        className={styles.FormInput}
                        placeholder={t("EMAIL_FIELD")}
                        {...register("email", {
                          onChange: (e) => {
                            passwordKeyPress(e);
                          },
                          required: {
                            value: true,
                            message: `${t("EMAIL_REQUIRED")}`,
                          },
                          pattern: {
                            value:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: `${t("INVALID_EMAIL")}`,
                          },
                        })}
                      />
                      {errors.email && (
                        <span className="errorMsg">{errors.email.message}</span>
                      )}
                    </Form.Group>

                    <div className={styles.signUpBtn}>
                      <button onClick={() => handleSubmit} disabled={isButtonDisabled}>
                        {t("RESET_BTN")}
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles.FormSection} ${styles.AccountSection}`}
                >
                  <p>
                    {t("RETURN_PARA")}
                    <NavLink to="/login">{t("LOGIN_OPTION")}</NavLink>
                  </p>
                </div>
              </Form>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
}

export default ForgotPassword;
