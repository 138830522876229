import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//-------function for show trainer images in home page----------- 
function HomePageSlider({ packageList }) {

  // Configure the slider settings
  const settings = {
    arrowLeft: true,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    // slidesToShow:
    //   packageList && packageList.get_success_story && packageList.get_success_story.length < 3
    //     ? packageList.get_success_story.length
    //     : 3,
    // variableWidth:true,
    // variableWidth: packageList && packageList.get_success_story && packageList.get_success_story.length > 3
    //   ? false
    //   : true,
    slidesToScroll: 5,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },

      {
        breakpoint: 400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },

      {
        breakpoint: 414,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },

      {
        breakpoint: 567,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        }
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
    ]
  };
  return (
    <div>
      <Container className="subContainerPage">
        <div className="text-center">
        </div>
        <div>
          <div className="fitnessSliderImg">
            <Slider {...settings} >
              {packageList && packageList.get_success_story && packageList.get_success_story.length > 0
                ? packageList.get_success_story.map((item, index) => (
                  <div key={index} className="sliderOutline">
                    <img src={item.image} alt={`Slide ${index + 1}`} />
                  </div>
                ))
                : ""}
            </Slider>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default HomePageSlider;
