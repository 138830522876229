import { Form } from "react-bootstrap";
import styles from "../Profile/EditProfile.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../utils/Toaster";
import { userLogoutClear } from "../../store/slices/UserSlice";
import { STATUS_CODES } from "../../utils/StatusCode";
import Loader from "../../utils/Loader/Loader";
import { useEffect, useState } from "react";
import $ from "jquery";

//-------function for update password field------- 
function UpdatePassword() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userToken } = useSelector((state) => state.user);
    const [loader, setLoader] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm();

    // --------function for open component top of the page ----------
    useEffect(() => {
        $("document").ready(function () {
            $(window).scrollTop(0);
        });
    }, []);

    const passwordKeyPress = (e) => {
        setValue(e.target.name, e.target.value.replace(/\s/g, '')); // this remove from all string
    };

    //-----function for update password api calling-------   
    const onSubmit = async (formdata) => {
        let requestData = new FormData();
        requestData.append("old_password", formdata ? formdata.oldPassword.trim() : "");
        requestData.append("new_password", formdata ? formdata.newPassword.trim() : "");
        requestData.append("new_confirm_password", formdata ? formdata.confirmPassword.trim() : "");
        setLoader(true);
        await SublyApi.updatePassword(requestData, userToken).then((response) => {
            if (response.settings.success == STATUS_CODES.SUCCESS_API) {
                Toast.fire({
                    icon: "success",
                    title: response.settings.message,
                });
                setLoader(false);
                setValue("oldPassword", "");
                setValue("newPassword", "");
                setValue("confirmPassword", "");
                navigate("/profile");
            } else if (response.settings.success == STATUS_CODES.INVALID_TOKEN) {
                Toast.fire({
                    icon: "error",
                    title: t("SESSION_EXPIRE"),
                });
                setLoader(false);
                dispatch(userLogoutClear())
                navigate("/login");
            }
            else {
                Toast.fire({
                    icon: "error",
                    title: response.settings.message,
                });
                setLoader(false);
            }
        })
    }

    return (
        <>
            {loader ? (
                <div className="loader">
                    <Loader />
                </div>
            ) : null}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.FormSection}>
                    <div className={styles.formBorder}>
                        <Form.Group className={styles.FormGroup}>
                            <Form.Label className={styles.formLabel}>
                                {t("PROFILE_OLD_PASSWORD")}
                            </Form.Label>
                            <Form.Control
                                className={styles.FormInput}
                                type="password"
                                {...register("oldPassword", {
                                    onChange: (e) => {
                                        passwordKeyPress(e);
                                    },
                                    required: {
                                        value: true,
                                        message: `${t("OLD_PASS")}`,
                                    },
                                    minLength: {
                                        value: 5,
                                        message: `${t("PASS_MINLENGTH")}`,
                                    },
                                    maxLength: {
                                        value: 30,
                                        message: `${t("PASS_MAXLENGTH")}`,
                                    },
                                })}
                            />
                            {errors.oldPassword && (
                                <span className="errorMsg">
                                    {errors.oldPassword.message}
                                </span>
                            )}
                        </Form.Group>
                    </div>
                    <div className={styles.formBorder}>
                        <Form.Group className={styles.FormGroup}>
                            <Form.Label className={styles.formLabel}>
                                {t("PROFILE_NEW_PASSWORD")}
                            </Form.Label>
                            <Form.Control
                                className={styles.FormInput}
                                type="password"
                                {...register("newPassword", {
                                    onChange: (e) => {
                                        passwordKeyPress(e);
                                    },
                                    required: {
                                        value: true,
                                        message: `${t("NEW_PASS")}`,
                                    },
                                    minLength: {
                                        value: 5,
                                        message: `${t("PASS_MINLENGTH")}`,
                                    },
                                    maxLength: {
                                        value: 30,
                                        message: `${t("PASS_MAXLENGTH")}`,
                                    },
                                })} />
                            {errors.newPassword && (
                                <span className="errorMsg">
                                    {errors.newPassword.message}
                                </span>
                            )}
                        </Form.Group>
                    </div>

                    <div className={styles.formBorder}>
                        <Form.Group className={styles.FormGroup}>
                            <Form.Label className={styles.formLabel}>
                                {t("PROFILE_CONFIRM_PASSWORD")}
                            </Form.Label>
                            <Form.Control
                                className={styles.FormInput}
                                type="password"
                                {...register("confirmPassword", {
                                    onChange: (e) => {
                                        passwordKeyPress(e);
                                    },
                                    required: {
                                        value: true,
                                        message: `${t("CONFIRM_PASS")}`,
                                    },
                                    minLength: {
                                        value: 5,
                                        message: `${t("PASS_MINLENGTH")}`,
                                    },
                                    maxLength: {
                                        value: 30,
                                        message: `${t("PASS_MAXLENGTH")}`,
                                    },
                                    validate: (value) =>
                                        value === watch("newPassword") ||
                                        `${t("NOT_MATCH")}`,
                                })} />
                            {errors.confirmPassword && (
                                <span className="errorMsg">
                                    {errors.confirmPassword.message}
                                </span>
                            )}
                        </Form.Group>
                    </div>
                    <div className={styles.updateBtn}>
                        <div className={styles.logoutBtn}>
                            <button>{t("UPDATE_BTN")}</button>
                        </div>
                        <div className={styles.cancelBtn}>
                            <button onClick={() => navigate("/profile")}>{t("CANCEL_BTN")}</button>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
}
export default UpdatePassword;