import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../Subscription/SubscriptionDetail.module.css";
import "../../../assets/Styles/Common.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import paypal from "../../../assets/Images/paypal.svg";
import { useEffect, useState } from "react";
import visa from "../../../assets/Images/paymentmethods.png";
import SubHeader from "../../HeaderSection/SubHeader";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "../../../utils/Toaster";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { useTranslation } from "react-i18next";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import { PLAN, TRANSACTION_TYPE, SESION_TRANSACTION_TYPE } from "../../../utils/Constants";
import Loader from "../../../utils/Loader/Loader";
import $ from "jquery";
import Swal from "sweetalert2";

//--------function for paypal order create on sale-----------
function FitnessPlanPaypal() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const details = location.state;
    const [orderID, setOrderID] = useState("");
    const [planDetails, setPlanDetails] = useState("");
    const { userToken, currentUser } = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(false);

    // --------function for open component top of the page ----------
    useEffect(() => {
        $("document").ready(function () {
            $(window).scrollTop(0);
        });
    }, []);

    //---------Design paypal button-----------
    const buttonStyle = {
        layout: 'horizontal',
        color: 'blue',
        shape: 'pill',
        label: 'buynow',
        size: 'medium',
        tagline: false,
        height: 45,
    };

    // ---------function for workout plan details api calling---------- 
    useEffect(() => {
        const id = details && details.planDetails && details.planDetails.workout_id;
        const type = details && details.planDetails && details.planDetails.plan_type == "workout_plan" ? PLAN.WORKOUT_PLAN : PLAN.STREAM_WORKOUT_PLAN;
        async function getPlanDetails() {
            setIsLoading(true);
            const workoutPlan = await SublyApi.workoutPlanDetails(id, type, (currentUser && currentUser.customer_id) ? currentUser.customer_id : "");
            setIsLoading(false);
            if (workoutPlan && workoutPlan.settings && workoutPlan.settings.success && workoutPlan.settings.success == STATUS_CODES.SUCCESS_API) {
                setPlanDetails(workoutPlan && workoutPlan.settings.data);
            }
        }
        getPlanDetails();
    }, []);


    async function ManageSessionExpire() {
        const requestData = new FormData();
        requestData.append("transaction_type", SESION_TRANSACTION_TYPE.SALE);
        requestData.append("plan_id", planDetails && planDetails.workout_id);
        requestData.append("plan_type", planDetails && planDetails.plan_type == "workout_plan"
            ? PLAN.WORKOUT_PLAN
            : PLAN.STREAM_WORKOUT_PLAN);
        return await SublyApi.sessionManage(requestData, userToken).then((response) => {
            if (response.settings.success == STATUS_CODES.SUCCESS_API) {
                return true;
            }
            else if (response && response.settings && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
                Toast.fire({
                    icon: "error",
                    title: t("SESSION_EXPIRE"),
                });
                dispatch(userLogoutClear());
                navigate("/login");
            } else {
                return false;
            }
        }
        );
    }

    //---------creates a paypal order-----------
    const createOrder = async (data, actions) => {
        const res = await ManageSessionExpire();
        if (res) {
            return actions.order
                .create({
                    purchase_units: [
                        {
                            amount: {
                                currency_code: planDetails && planDetails.currency,
                                value: planDetails && planDetails.amount,
                            },
                        },
                    ],
                })
                .then((orderID) => {

                    setOrderID(orderID);
                    return orderID;
                });
        } else {
            return false;
        }
    };


    //--------Save details of sale purchase api integration--------- 
    const onApprove = (data, actions) => {
        return actions.order.capture().then(async function (paymentData) {
            // Handle successful payment
            let requestData = new FormData();
            requestData.append("transaction_type", TRANSACTION_TYPE.SALE);
            requestData.append("transaction_sub_type", planDetails && planDetails.plan_type == "workout_plan" ? PLAN.WORKOUT_PLAN : PLAN.STREAM_WORKOUT_PLAN);
            requestData.append("plan_type", planDetails && planDetails.plan_type);
            requestData.append("transaction_sub_type_id", planDetails && planDetails.workout_id);
            requestData.append("order_id", paymentData.id);
            requestData.append("transaction_id", paymentData.purchase_units[0].payments.captures[0].id);
            requestData.append("payment_json", JSON.stringify(paymentData));
            await SublyApi.salePurchase(requestData, userToken).then(async (response) => {
                if (response.success == STATUS_CODES.SUCCESS_API) {
                    if (requestData.get("plan_type") == "workout_plan") {
                        await SublyApi.salePlanInfo(userToken, requestData.get('transaction_sub_type_id'), (currentUser && currentUser.customer_id) ? currentUser.customer_id : "").then(async (response) => {
                            if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
                                Swal.fire({
                                    title: `THANK YOU FOR PURCHASING THIS PLAN`,
                                    html: `This plan has been added to "My Profile, My Plan" on the 3fitstyle app. Kindly log in to the app with your username to view this workout plan. NOTE, : You can only access content from the app not on this website. This website is only for making payment.<br><br>Thank you!</br>3fitstyle`,
                                    icon: "success",
                                    allowOutsideClick: false,
                                    customClass: "swalAlertBox",
                                    backdrop: true,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        navigate("/profile/2");
                                    }
                                });
                            } else if (response && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
                                Toast.fire({
                                    icon: "error",
                                    title: t("SESSION_EXPIRE"),
                                });
                                dispatch(userLogoutClear());
                                navigate("/login");
                            }
                        });
                    } else {
                        Swal.fire({
                            title: `THANK YOU FOR PURCHASING THIS PLAN`,
                            html: `This plan has been added to "My Profile, My Plan" on the 3fitstyle app. Kindly log in to the app with your username to view this workout plan. NOTE, : You can only access content from the app not on this website. This website is only for making payment.<br><br>Thank you!</br>3fitstyle`,
                            icon: "success",
                            allowOutsideClick: false,
                            customClass: "swalAlertBox",
                            backdrop: true,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate("/profile/2");
                            }
                        });
                    }
                } else if (response && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
                    Toast.fire({
                        icon: "error",
                        title: t("SESSION_EXPIRE"),
                    });
                    dispatch(userLogoutClear());
                    navigate("/login");
                } else {
                    Toast.fire({
                        icon: "error",
                        title: response.message,
                    });
                }
            });
        });
    };



    return (
        <div className="main">
            {isLoading === true ? <Loader /> : ""}
            <SubHeader />
            {planDetails ?
                <Container>
                    <div className={styles.subDetail}>
                        <div className={styles.subscriptionPayBox}>
                            <div className={styles.summaryTexts}>
                                <h2>{t("SALE_SUMMARY")}</h2>
                            </div>
                            <div className={styles.payTexts}>
                                <p>{t("PLAN_TEXT")}</p>
                                {planDetails && planDetails.workout_name ?
                                    <p>{planDetails && planDetails.workout_name}</p> :
                                    <p>{t("EXCLUSIVE")}</p>
                                }
                            </div>
                            <div className={styles.timeTexts}>
                                <p>{t("PRICE_TEXT")}</p>
                                <p>{planDetails && planDetails.currency_symbol}{planDetails && planDetails.amount}</p>
                            </div>
                            <div className={styles.priceTexts}>
                                <p>{t("TOTAL_PRICE")}</p>
                                <p>{planDetails && planDetails.currency_symbol}{planDetails && planDetails.amount}</p>
                            </div>
                        </div>

                        <div className={styles.subscriptionDetailText}>
                            <p>
                                {t("SUBSCRIPTION_NOTE")}<span> {t("SUBSCRIPTION_NOTE_PARA")}</span>
                            </p>
                        </div>

                        {/* {planDetails && planDetails.acess_level && planDetails.acess_level == ACCESS_LEVEL.LOCK ? */}

                        {planDetails &&
                            planDetails.amount == "0" ?
                            "" :
                            <>
                                <div className={styles.backButton}>
                                    <div className={styles.payPal}>
                                        <PayPalScriptProvider options={{ clientId: process.env.REACT_APP_CLIENT_ID }}>
                                            <PayPalButtons
                                                createOrder={createOrder}
                                                onApprove={onApprove}
                                                style={buttonStyle}
                                            />
                                        </PayPalScriptProvider>
                                    </div>
                                </div>
                                <div className={styles.payPalImg}>
                                    <img src={visa} alt="logo" />
                                </div>
                                <div className={styles.poweredImg}>
                                    <h4>{t("POWERED_BY")}</h4>
                                    <img src={paypal} alt="logo" />
                                </div>
                            </>}
                        {/* : ""} */}
                    </div>
                </Container> : ""}
        </div>
    );
}
export default FitnessPlanPaypal;
