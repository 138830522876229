import { CONTENT } from "../../utils/Constants";
import { Container } from "react-bootstrap";
import styles from "../Content/Content.module.css";
import SublyApi from "../../helpers/Api";
import { useEffect, useState } from "react";
import DOMPurify from 'dompurify';
import { useTranslation } from "react-i18next";
import { STATUS_CODES } from "../../utils/StatusCode";

//----------function for terms and condition content----------- 
function TermsCondition() {
    const { t } = useTranslation();
    const [contentList, setContentList] = useState("");

    // --------function for open component top of the page ----------
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    //---------function for get terms and condition content api calling---------- 
    useEffect(() => {
        async function privacyContent() {
            const details = await SublyApi.getContent(CONTENT.TERMS_CONDITION);
            if (details && details.settings && details.settings.success && details.settings.success == STATUS_CODES.SUCCESS_API) {
                setContentList(details && details.data.page_content);
            }
        }
        privacyContent();
    }, []);

    return (
        <>
            {contentList &&
                <div className="main">
                    <div className={styles.contentText}>
                        <Container>
                            <h2>{t("TERMS_CONDITION")}</h2>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(contentList)
                                }}>
                            </div>
                        </Container>
                    </div>
                </div>
            }
        </>
    );
}
export default TermsCondition;