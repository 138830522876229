import styles from "../Subscription/Subscription.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SublyApi from "../../helpers/Api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HomePageSlider from "../../commonComponents/HomePageSlider/HomePageSlider";
import SubHeader from "../HeaderSection/SubHeader";
import { STATUS_CODES } from "../../utils/StatusCode";
import Loader from "../../utils/Loader/Loader";
import $ from "jquery";

//---------function for subscription page---------- 
function Subscription() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);
  const [packageList, setPackageList] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  //---------function for subscription api calling----------
  useEffect(() => {
    async function subscriptionPackageList() {
      setIsLoading(true);
      const details = await SublyApi.getPackageList();
      setIsLoading(false);
      if (details && details.settings && details.settings.success && details.settings.success == STATUS_CODES.SUCCESS_API) {
        setPackageList(details && details.data);
      }
    }
    subscriptionPackageList();
  }, []);

  return (
    <>
      <div className="newSubmain">
        {isLoading === true ? <Loader /> : ""}
        <SubHeader />
        <div className={styles.subscription}>
          {packageList ?
            <div className={styles.subscriptionContent}>
              <Container>
                {packageList && packageList.get_static_content.length >= 1 && (
                  <div className="text-center">
                    <div className={styles.subscriptionOffer}>
                      <h2>{packageList.get_static_content[0].title}</h2>
                      <p>{packageList.get_static_content[0].content}</p>
                    </div>
                  </div>
                )}
                {packageList && packageList.get_all_packages.length < 3 ? (
                  <Row className={styles.subscriptionInner}>
                    {packageList && packageList.get_all_packages.length > 0 &&
                      packageList.get_all_packages.map((item, index) => (
                        <>
                          <Col
                            lg={4}
                            md={3}
                            sm={6}
                            xs={3}
                            className={styles.subRow}
                            key={item.index}
                          >
                            <div
                              className={styles.subscriptionbox}
                              style={{
                                background: `${item.color_code}`,
                              }}
                            >
                              <p className={styles.timeSection}>
                                {item.package_name}
                              </p>
                              <div className={styles.subborder}>
                                <span></span>
                              </div>
                              <div className={styles.subText}>
                                <p>{item.package_description}</p>
                              </div>
                              <div className={styles.amountSection}>
                                {item.subscription_amount &&
                                  <p>
                                    $<span>{item.subscription_amount}</span>
                                  </p>}
                                <p className={styles.monthText}></p>
                              </div>
                              <div className={styles.subBtn}>
                                {Object.keys(currentUser).length == 0 ? (
                                  <button
                                    onClick={() => {
                                      navigate("/login", {
                                        state: {
                                          packageName: true,
                                          packageDetail: item,
                                        },
                                      });
                                    }}
                                  >
                                    {t("SUBSCRIBE")}
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      navigate("/subscription/detail", {
                                        state: {
                                          packageName: true,
                                          packageDetail: item,
                                        },
                                      })

                                    }
                                  >
                                    {t("SUBSCRIBE")}
                                  </button>
                                )}
                              </div>
                            </div>
                          </Col>
                        </>
                      ))}
                  </Row>
                ) : (
                  <Row className={styles.subscriptionInners}>
                    {packageList && packageList.get_all_packages.length > 0 &&
                      packageList.get_all_packages.map((item, index) => (
                        <>
                          <Col
                            lg={4}
                            md={3}
                            sm={6}
                            xs={3}
                            className={styles.subRow}
                            key={item.index}
                          >
                            <div
                              className={styles.subscriptionbox}
                              style={{
                                background: `${item.color_code}`,
                              }}
                            >
                              <p className={styles.timeSection}>
                                {item.package_name}
                              </p>
                              <div className={styles.subborder}>
                                <span></span>
                              </div>
                              <div className={styles.subText}>
                                <p>{item.package_description}</p>
                              </div>
                              <div className={styles.amountSection}>
                                {item.subscription_amount &&
                                  <p>
                                    $<span>{item.subscription_amount}</span>
                                  </p>}
                                <p className={styles.monthText}></p>
                              </div>
                              <div className={styles.subBtn}>
                                {Object.keys(currentUser).length == 0 ? (
                                  <button
                                    onClick={() => {
                                      navigate("/login", {
                                        state: {
                                          packageName: true,
                                          packageDetail: item,
                                        },
                                      });
                                    }}
                                  >
                                    {t("SUBSCRIBE")}
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      navigate("/subscription/detail", {
                                        state: {
                                          packageName: true,
                                          packageDetail: item,
                                        },
                                      })
                                    }
                                  >
                                    {t("SUBSCRIBE")}
                                  </button>
                                )}
                              </div>
                            </div>
                          </Col>
                        </>
                      ))}
                  </Row>
                )}

                <div className={styles.borderBottom}>
                  <span></span>
                </div>
              </Container>

              <Container>
                {packageList && packageList.get_static_content.length > 1 && (
                  <div className="text-center">
                    <div className={styles.subTerms}>
                      <h2>{packageList.get_static_content[1].title}</h2>
                      <p>{packageList.get_static_content[1].content}</p>
                    </div>
                    <div className={styles.borderBottom}>
                      <span></span>
                    </div>
                  </div>
                )}
              </Container>

              <Container className="sliderContent">
                {packageList &&
                  <div className={styles.subBorder}>
                    <div className={styles.storyTittle}>
                      <h2>{t("HEADING2")}</h2>
                    </div>
                    <HomePageSlider packageList={packageList} />
                  </div>
                }
              </Container>
              <Container>
                {packageList &&
                  <div className={styles.borderBottom}>
                    <span></span>
                  </div>
                }
              </Container>

              <Container>
                {packageList && packageList.get_subscription_terms.length > 0 &&
                  packageList.get_subscription_terms.map((item, index) => (
                    <div className="text-center" key={index}>
                      <div className={styles.subTerms}>
                        <h2>{item.title}</h2>
                        <p>{item.content}</p>
                      </div>
                    </div>
                  ))}
              </Container>
            </div> : ""
          }
        </div>
      </div>
    </>
  );
}
export default Subscription;
