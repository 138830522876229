import { Container, Form } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../utils/Toaster";
import Logo from "../../assets/Images/black_Icon.png";
import styles from "../ForgotPassword/ForgotPassword.module.css";
import { STATUS_CODES } from "../../utils/StatusCode";
import Loader from "../../utils/Loader/Loader";
import { useEffect, useState } from "react";
import $ from "jquery";

// ---------function for reset password ------------
function ResetPassword() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const queryParams = new URLSearchParams(window.location.search)
    const code = queryParams.get("code")


    // --------function for open component top of the page ----------
    useEffect(() => {
        $("document").ready(function () {
            $(window).scrollTop(0);
        });
    }, []);

    const passwordKeyPress = (e) => {
        setValue(e.target.name, e.target.value.replace(/\s/g, '')); // this remove from all string
    };

    //--------function for reset password api calling--------
    const onSubmit = async (formdata) => {
        let requestData = new FormData();
        requestData.append("code", code);
        requestData.append("new_password", formdata.password.trim());
        setLoader(true);
        await SublyApi.resetPasword(requestData).then((responsejson) => {
            if (responsejson && responsejson.settings.success == STATUS_CODES.SUCCESS_API) {
                setValue("password", "");
                Toast.fire({
                    icon: "success",
                    title: responsejson.settings.message,
                });
                setLoader(false);
                navigate("/login");
            } else {
                Toast.fire({
                    icon: "error",
                    title: responsejson && responsejson.settings.message,
                });
                setLoader(false);
            }
        })
    };

    return (
        <>
            {loader ? (
                <div className="loader">
                    <Loader />
                </div>
            ) : null}
            <div className="main">
                <section className={styles.resetPasswordSet}>
                    <Container>
                        <div className={styles.FormBlock}>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className={styles.FormSection}>
                                    <div className={styles.SignupTitleSet}>
                                        <NavLink to="/">
                                            <img src={Logo} alt="logo" />
                                        </NavLink>
                                        <h2>{t("UPDATE_PASSWORD")}</h2>
                                        <p>{t("NEW_PASSWORD")}</p>
                                    </div>

                                    <div>
                                        <Form.Group className={styles.FormGroupSet}>
                                            <Form.Control
                                                className={styles.FormInputSet}
                                                placeholder={t("ENTER_PASSWORD")}
                                                type="password"
                                                {...register("password", {
                                                    onChange: (e) => {
                                                        passwordKeyPress(e);
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: `${t("PASSWORD_REQUIRED")}`,
                                                    },
                                                })}
                                            />
                                            {errors.password && (
                                                <span className="errorMsg">
                                                    {errors.password.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                        <div className={styles.signUpBtnSet}>
                                            <div className={styles.signUpBtn}>
                                                <button onClick={() => handleSubmit}>
                                                    {t("UPDATE_PASSWORD")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Container>
                </section>
            </div>
        </>
    );
}

export default ResetPassword;
