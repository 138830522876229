import { Container, Form } from "react-bootstrap";
import styles from "./SignUp.module.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/Images/black_Icon.png";
import AppStore from "../../assets/Images/appstore.png";
import PlayStore from "../../assets/Images/playstore.png";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { clearForm, userLogin } from "../../store/slices/UserSlice";
import { Toast } from "../../utils/Toaster";
// import FacebookSocialLogin from "../../commonComponents/SocialLogin/FacebookSocialLogin";
import GoogleSocial from "../../commonComponents/SocialLogin/GoogleSocial";
import { STATUS_CODES } from "../../utils/StatusCode";
import { useEffect } from "react";
import Loader from "../../utils/Loader/Loader";
import SubHeader from "../../appComponents/HeaderSection/SubHeader";
import $ from "jquery";

// --------function for login form--------
function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const { isLoading } = useSelector((state) => state.user);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(clearForm(true));
  }, [dispatch]);

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  const passwordKeyPress = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, '')); // this remove from all string
  };


  // --------function for submit login form--------
  const onSubmit = async (formdata) => {
    const data = { "password": formdata.password.trim(), "userName": formdata.userName.trim() }
    dispatch(userLogin(data)).then((responsejson) => {
      const response = responsejson.payload;
      if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
        Toast.fire({
          icon: "success",
          title: response.settings.message,
        });

        if (location && location.state) {
          if (location.state.packageName == true) {
            navigate("/subscription/detail", {
              state: {
                packageName: true,
                packageDetail: location.state.packageDetail,
              },
            });
          } else if (location.state.planSection == true) {
            navigate("/exclusive/paypal", {
              state: {
                planSection: true,
                planDetails: location.state.planDetails,
              },
            });
          } else {
            navigate("/profile");
          }
        } else {
          navigate("/profile");
        }
      }
      else {
        Toast.fire({
          icon: "error",
          title: response && response.settings.message,
        });
      }
    });
  };

  return (
    <>
      {isLoading === true ? <Loader /> : ""}
      <div className="main">
        <section className={`${styles.SignUpWrapper} SignUpPage`}>
          <Container>
            <div className={styles.FormBlock}>
              <div className={styles.FormSection}>
                <div className={styles.SignupTitle}>
                  <NavLink to="/">
                    <img src={Logo} alt="logo" />
                  </NavLink>
                </div>
                {/* <FacebookSocialLogin /> */}
                <GoogleSocial/>
                <div className={styles.Line}>
                  <span className={styles.LineContent}>{t("OR")}</span>
                </div>
                <h5 className={styles.signTextClass}>{t("SIGN_USERNAME")}</h5>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <Form.Group className={styles.FormGroup}>
                      <Form.Control
                        className={styles.FormInput}
                        placeholder={t("PROFILE_USERNAME")}
                        type="text"
                        {...register("userName", {
                          onChange: (e) => {
                            passwordKeyPress(e);
                          },
                          required: {
                            value: true,
                            message: `${t("USERNAME_REQUIRED")}`,
                          },
                          pattern: {
                            value: /^\S/,
                            message: `${t("USERNAME_REQUIRED")}`,
                          },
                        })}
                      />
                      {errors.userName && (
                        <span className="errorMsg">
                          {errors.userName.message}
                        </span>
                      )}
                    </Form.Group>
                    <Form.Group className={styles.FormGroup}>
                      <Form.Control
                        className={styles.FormInput}
                        placeholder={t("PASSWORD")}
                        type="password"
                        {...register("password", {
                          onChange: (e) => {
                            passwordKeyPress(e);
                          },
                          required: {
                            value: true,
                            message: `${t("PASSWORD_REQUIRED")}`,
                          },
                        })}
                      />

                      {errors.password && (
                        <span className="errorMsg">
                          {errors.password.message}
                        </span>
                      )}
                    </Form.Group>
                    <div className={styles.Terms}>
                      <p>
                        {t("SIGNIN_TERMS")}{" "}
                        <NavLink to="/privacy-policy"> {t("PRIVACY_POLICY")} </NavLink>{" "}
                        {t("AND_OR")} <NavLink to="/terms-condition">{t("TERMS_OF_USE")}</NavLink>
                      </p>
                    </div>

                    <div className={styles.signUpBtn}>
                      <button>{t("SIGN_IN")}</button>
                    </div>
                    <div className={styles.forgotDiv}>
                      <p className={styles.joinText}>{t("MEMBER")} <NavLink to="/sign-up"> {t("Join_Us")}</NavLink></p>
                      <p>
                        <NavLink to="/forgot-password">
                          {t("FORGOT_PASSWORD")}
                        </NavLink>
                      </p>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
}

export default Login;
