import styles from "../Plan/Plan.module.css";
import "../../assets/Styles/Common.css";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FitnessPlan from "./FitnessPlanSection/FitnessPlan";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGE } from "../../utils/Constants";
import { getWorkoutPlan } from "../../store/slices/PlanListSlice";
import Loader from "../../utils/Loader/Loader";
import $ from "jquery"
import SubHeader from "../HeaderSection/SubHeader";

//------function for plan section------------
function Plan() {
  const { t } = useTranslation();
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.planList);

  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  //---------function for plan list api calling----------
  useEffect(() => {
    async function planList() {
      await dispatch(
        getWorkoutPlan({
          page: PAGE.PAGEONE,
          id:
            currentUser && currentUser.customer_id
              ? currentUser.customer_id
              : "",
        })
      );
    }
    planList();
  }, []);

  return (
    <div className="main">
      {isLoading ? <Loader /> : ""}
      <SubHeader />
      <div className="widthManage">
        <div className={styles.planTopSection}>
          <Container>
            <div className={styles.planHeading}>
              <h1>{t("PLAN_HEADING")}</h1>
              <p>{t("PLAN_PARA")}</p>
            </div>
            <FitnessPlan />
          </Container>
        </div>
      </div>
    </div>
  );
}

export default Plan;
