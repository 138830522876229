const monthsData = [];

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

for (let i = 0; i < month.length; i++) {
  monthsData.push({ label: month[i], value: i + 1 });
}

const dateData = [];
for (let i = 1; i <= 31; i++) {
  dateData.push({ value: i, label: i });
}

const Year = [];

for (let i = 1950; i <= 2023; i++) {
  Year.push({ value: i, label: i });
}

export { Year, dateData, monthsData };
