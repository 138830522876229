import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";

const initialState = {
  workoutPlanList: "",
  purchasePlanList: "",
  isLoading: false,
  success: false,
  error: null,
};

// Thunk for get workOut plan list
export const getWorkoutPlan = createAsyncThunk(
  "PlanList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getWorkoutPlanList(data.page, data.id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get purchased plan list
export const getPurchasedPlan = createAsyncThunk(
  "planList/getPurchasedPlan",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.saleDetailsShow(data.page, data.userToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const planListSlice = createSlice({
  name: "planList",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // ------Builder for plan list--------
    builder.addCase(getWorkoutPlan.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getWorkoutPlan.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
        state.workoutPlanList = response.settings.data;
        state.success = true;
      } else {
        state.success = false;
        state.workoutPlanList = [];
      }
    });

    builder.addCase(getWorkoutPlan.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for get purchased plan list-------
    builder.addCase(getPurchasedPlan.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getPurchasedPlan.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
        state.purchasePlanList = response.settings.data;
        state.success = true;
      } else {
        state.purchasePlanList = [];
        state.success = false;
      }
    });

    builder.addCase(getPurchasedPlan.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export default planListSlice.reducer;

