import axios from "axios";
import { DEVICE_TYPE } from "../utils/Constants";


const BASE_URL = process.env.REACT_APP_BASE_URL;
// const REACT_PROXYURL = "https://quiet-retreat-79741.herokuapp.com/";
const REACT_PROXYURL = "";


// for making unique id for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_ID)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(process.env.REACT_APP_DEVICE_ID, uint32.toString(32));
}

// for making unique token for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(process.env.REACT_APP_DEVICE_TOKEN, uint32.toString(32));
}

/** API Class
 * static clase trying together method used to get/send to the API.
 * There shouldn't be any frontend-specific stuff here, and there  shouldn't be any API-aware stuff else hre in the frontend...
 */
class SublyApi {
  //token which interact with the API will be stored here.
  static token;


  //required common header for each api calling.
  static commonHeaders = {
    // "api-key": "HBDEV",
    // "deviceToken": localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN),
    // "deviceId": localStorage.getItem(process.env.REACT_APP_DEVICE_ID),
    // "deviceType": DEVICE_TYPE.DEVICE_TYPE,
  };


  static commonParams = {
    "deviceToken": localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN),
    "deviceId": localStorage.getItem(process.env.REACT_APP_DEVICE_ID),
    "deviceType": DEVICE_TYPE.DEVICE_TYPE,
  };

  static async removeExtraSlashes(url) {
    // Replace multiple consecutive forward slashes with a single forward slash
    // return url.replace(/\/\/+/g, '/');
    return url.replace(/([^:]\/)\/+/g, "$1")
}


  static async request(endpoint, data = {}, method = "get", header) {
    // const url = `${REACT_PROXYURL}${BASE_URL}${endpoint}`;
    const url =  await this.removeExtraSlashes(`${REACT_PROXYURL}${BASE_URL}${endpoint}`);
    const headers = { ...SublyApi.commonHeaders, ...header };
    const params = method === "get" ? data : {};
    try {
      const result = await axios({ url, method, data, params, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  /******************************************************
   * USERS AUTH API ROUTES
   *******************************************************/

  /* GET  /customer login:  {device_id,device_type,password,user_name}
 login api
 Authorization required:none
 @param data {Object} {device_id,device_type,password,user_name}
 @returns {object} {token}
 */
  static async loginProcess(password, user_name) {
    let res = await this.request(`WS/customer_login?device_id=${SublyApi.commonParams.deviceId}&device_type=${SublyApi.commonParams.deviceType}&password=${password}&user_name=${user_name}`, "", "get");
    return res;
  }

  /* GET  /social login:  {device_id,device_type,social_id,network_type}
Authorization required:none
@param data {Object} {device_id,device_type,social_id,network_type}
@returns {object} {token}
*/
  static async checkSocialLogin(social_network_id, social_network_type) {
    let res = await this.request(`WS/customer_login?device_id=${SublyApi.commonParams.deviceId}&device_type=${SublyApi.commonParams.deviceType}&social_network_id=${social_network_id}&social_network_type=${social_network_type}`, "", "get");
    return res;
  }

  /* GET  /customer details:  {device_token}
customer details
Authorization required:Token
@param data {Object} {device_token}
@returns {object} {token}
*/
  static async getCustomerDetails(authToken) {
    let header = { "auth-token": `${authToken}` };
    let res = await this.request(`/webservice_v2/customer/detail?device_token=${SublyApi.commonParams.deviceToken}`, "", "get", header);
    return res;
  }

  /* POST  /customer profile update
update profile
Authorization required:Token
@returns {object} {token}
*/
  static async updateCustomerDetails(data, authToken) {
    let header = { "auth-token": `${authToken}` };
    let res = await this.request(`webservice_v2/customer/update_customer_detail`, data, "post", header);
    return res;
  }

  /* POST  /email_availability api 
 Authorization required:none
 @param data {Object} {userName}
 @returns {object} {token}
 */
  static async emailAvailbility(data) {
    let res = await this.request(`WS/email_availability`, data, "post");
    return res;
  }

  /* POST  /userSignUp api : {name, email, password, confirmPassword}
  Authorization required:none
  @param data {Object} {userName}
  @returns {object} {token}
  */
  static async userAvalibility(data) {
    let res = await this.request(`WS/user_availability`, data, "post");
    return res;
  }

  /* POST  /SignUp api : {profile_pic,name,email,mobile_number,isd_code,country_id,gender,height,email_upadates,password, weight,     user_name,  dob,    device_type,    device_id,   device_token,  social_network_id,    social_network_type,}
  Authorization required:none
  @param data {Object} {userName}
  @returns {object} {token}
  */
  static async userSignup(data) {
    let res = await this.request(`WS/sign_up`, data, "post");
    return res;
  }

  /* GET  /forgot password:  
Authorization required:none
@param data {Object} 
@returns {object} {token}
*/
  static async forgotPassword(email, device_type) {
    let res = await this.request(`webservice_v2/forgot_paasword?email=${email}&device_type=${device_type}`, "", "get");
    return res;
  }

  /* POST  /Password Update
Authorization required:Token
@returns {object} {token}
*/
  static async updatePassword(data, authToken) {
    let header = { "auth-token": `${authToken}` };
    let res = await this.request(`webservice_v2/change_password`, data, "post", header);
    return res;
  }

  /* POST  /Contact_Us api
Authorization required:none
@returns {object} {token}
*/
  static async contactUs(data) {
    let res = await this.request(`webservice_v2/contactUs`, data, "post");
    return res;
  }

  /* GET  / subscription package list
Authorization required:none
@returns {object} {token}
*/
  static async getPackageList() {
    let res = await this.request(
      `/webservice_v2/web_package_list?device_token=${SublyApi.commonParams.deviceToken}&device_type=${SublyApi.commonParams.deviceType}`,
      "",
      "get"
    );
    return res;
  }


  /* POST  /Reset Password api
Authorization required:none
@returns {object} {token}
*/
  static async resetPasword(data) {
    let res = await this.request(`webservice_v2/reset_password`, data, "post");
    return res;
  }

  /* GET  / FAQ Api 
Authorization required:none
@returns {object} {token}
*/
  static async getFaqList() {
    let res = await this.request(
      `WS/get_faq?device_id=${SublyApi.commonParams.deviceId}&device_type=${SublyApi.commonParams.deviceType}`, "", "get");
    return res;
  }

  /* GET  / Get Content Api 
Authorization required:none
@returns {object} {token}
*/
  static async getContent(page_code) {
    let res = await this.request(
      `webservice_v2/get_content?page_code=${page_code}`, "", "get");
    return res;
  }

  /* GET  / GET Home Sliders List API
Authorization required:none
@returns {object} {token}
*/
  static async getHomeSliderImage(image_type) {
    let res = await this.request(
      `webservice_v2/slider_image_list?image_type=${image_type}`, "", "get");
    return res;
  }

  /* GET  / GET Workout Plan List
Authorization required:none
@returns {object} {token}
*/
  static async getWorkoutPlanList(page, user_id) {
    let res = await this.request(
      `webservice_v2/sales_plan?page=${page}&user_id=${user_id}`, "", "get");
    return res;
  }

  /* GET  / GET Workout Plan Details
Authorization required:none
@returns {object} {token}
*/
  static async workoutPlanDetails(plan_id, plan_type, user_id) {
    let res = await this.request(
      `/webservice_v2/sales_plan_details?plan_id=${plan_id}&plan_type=${plan_type}&user_id=${user_id}`, "", "get");
    return res;
  }


  /* GET  / Global setting for sales plan visibility status
Authorization required:none
@returns {object} {token}
*/
  static async salesPlanVisibility() {
    let res = await this.request(
      `/webservice_v2/global_setting`, "", "get");
    return res;
  }

  /* POST  /Sales purchase API: 
Authorization required:Token
@returns {object} {token}
*/
  static async salePurchase(data, authToken) {
    let header = { "auth-token": `${authToken}` };
    let res = await this.request(`/webservice_v2/plan_subscription`, data, "post", header);
    return res;
  }


  /* POST  /Subscription paypal API: 
Authorization required:Token
@returns {object} {token}
*/
  static async subscriptionCheckOut(data, authToken) {
    let header = { "auth-token": `${authToken}` };
    let res = await this.request(`/webservice_v2/subscription_checkout`, data, "post", header);
    return res;
  }

  /* GET  /Subscription_checkout_detail API:: 
Authorization required:Token
@returns {object} {token}
*/
  static async subscriptionDetail(authToken) {
    let header = { "auth-token": `${authToken}` };
    let res = await this.request(`/webservice_v2/subscription_checkout_detail`, undefined, "get", header);
    return res;
  }

  /* POST  /Subscriptions remove API: 
 Authorization required:Token
 @returns {object} {token}
 */
  static async subscriptionRemove(authToken) {
    let header = { "auth-token": `${authToken}` };
    let res = await this.request(`/webservice_v2/remove_subscription_plan`, undefined, "post", header);
    return res;
  }

  /* GET  /Customer Plan list  : 
Authorization required:Token
@returns {object} {token}
*/
  static async saleDetailsShow(page_index, authToken) {
    let header = { "auth-token": `${authToken}` };
    let res = await this.request(`/webservice_v2/get_customer_plans?page_index=${page_index}`, "", "get", header);
    return res;
  }

  /* GET  / sale plan info (if plan type is workout plan)
Authorization required:none
@returns {object} {token}
*/
  static async salePlanInfo(authToken, program_id, auth_customer_id) {
    // let header = { "auth-token": `${authToken}` };
    let res = await this.request(
      `/WS/add_to_my_plan?device_id=${SublyApi.commonParams.deviceId}&device_type=${SublyApi.commonParams.deviceType}&program_id=${program_id}&auth_customer_id=${auth_customer_id}`, "", "get");
    return res;
  }

  /* GET  / GET Customer My Plan details API
Authorization required:none
@returns {object} {token}
*/
  static async planPurchaseDetails(plan_id, plan_type, trans_id, authToken) {
    let header = { "auth-token": `${authToken}` };
    let res = await this.request(
      `/webservice_v2/customer_plans_details?plan_id=${plan_id}&plan_type=${plan_type}&trans_id=${trans_id}`, "", "get", header);
    return res;
  }

  /* POST  /Check_session API: 
Authorization required:Token
@returns {object} {token}
*/
  static async sessionManage(data, authToken) {
    let header = { "auth-token": `${authToken}` };
    let res = await this.request(`/webservice_v2/check_session`, data, "post", header);
    return res;
  }

}

export default SublyApi;