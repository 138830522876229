import { Container, Row, Col, Form } from "react-bootstrap";
import styles from "./Profile.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../../assets/Styles/Common.css";
import UserProfile from "./UserProfile";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customerDeatail, setProfileTabValue, userLogoutClear } from "../../store/slices/UserSlice";
import Loader from "../../utils/Loader/Loader";
import moment from 'moment';
import { STATUS_CODES } from "../../utils/StatusCode";
import HeaderSub from "../HeaderSection/HeaderSub";
import SubscriptionData from "../Subscription/SubscriptionData";
import $ from "jquery";
import PurchaseData from "../Plan/PurchaseData/PurchaseData";

// --------function for get profile details--------
function Profile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken, isLoading,updateUser, salePlanVisible, profileTabValue } = useSelector((state) => state.user);
  const { purchasePlanList } = useSelector((state) => state.planList);
  const [customerData, setCustomerData] = useState("");
  const { id } = useParams();

  let defaultTabValue = "info";
  if (id == 1) {
    defaultTabValue = "subscription";
  } else if (id == 2) {
    defaultTabValue = "sale";
  } else if (id == 3) {
    defaultTabValue = "info";
  } else {
    if (!id) {
      defaultTabValue = profileTabValue;
    }
  }
  // const defaultTabValue = (id == 1) ? "subscription" : ((id == 2) ? "sale" : "info");
  const [defaultTab, setDefaultTab] = useState(defaultTabValue);

  // --------function for open component top of the page ----------
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  // --------function for get profile details api call--------
  useEffect(() => {
    dispatch(customerDeatail(userToken)).then((responsejson) => {
      const response = responsejson.payload;
      if (response && response.settings && response.settings.success && response.settings.success == STATUS_CODES.SUCCESS_API) {
        setCustomerData(response.data[0]);
      }
      else if (response && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
        // Toast.fire({
        //   icon: "error",
        //   title: t("SESSION_EXPIRE"),
        // });
        dispatch(userLogoutClear())
        navigate("/login");
      }
    })
  }, [])

  return (
    <>
      <div className="main">
        {isLoading === true ? <Loader /> : ""}
        <HeaderSub />
        <div className={styles.profileSection}>
          <div className="containerManage">
            <Container>
              <div className={styles.profileBlock}>
                <Row>
                  <Col md={4}>
                    <UserProfile />
                  </Col>
                  <Col md={8}>
                    <div className={styles.topRight}>
                      <Tabs
                        defaultActiveKey={defaultTab}
                        id="uncontrolled-tab-example"
                        className="tabSection tabClass"
                        onSelect={(k) => dispatch(setProfileTabValue(k))}
                      >
                        <Tab eventKey="info" title="Basic Info">
                          <div className="ManageTopSpace">
                            <div className={styles.basicInfo}>
                              <Form>
                                <div className={styles.userInfo}>
                                  <Form.Group
                                    className="mb-2"
                                  >
                                    <Form.Label className={styles.formLabel}>
                                      {t("PROFILE_FULLNAME")}
                                    </Form.Label>
                                    <div className="capitalLetters">
                                      <p>{updateUser && updateUser.customer_full_name && updateUser.customer_full_name.charAt(0).toUpperCase() + updateUser.customer_full_name.slice(1).toLowerCase()}</p>
                                    </div>
                                  </Form.Group>
                                </div>
                                <div className={styles.userInfo}>
                                  <Form.Group
                                    className="mb-2"
                                  >
                                    <Form.Label className={styles.formLabel}>
                                      {t("PROFILE_USERNAME")}
                                    </Form.Label>
                                    <p>{updateUser && updateUser.customer_user_name && updateUser.customer_user_name}</p>
                                  </Form.Group>
                                </div>
                                <div className={styles.userInfo}>
                                  <Form.Group
                                    className="mb-2"
                                  >
                                    <Form.Label className={styles.formLabel}>
                                      {t("EMAIL")}
                                    </Form.Label>
                                    <p>{updateUser && updateUser.customer_email && updateUser.customer_email}</p>
                                  </Form.Group>
                                </div>

                                <div className={styles.userInfo}>
                                  <Form.Group
                                    className="mb-2"
                                  >
                                    <Form.Label className={styles.formLabel}>
                                      {t("PROFILE_DOB")}
                                    </Form.Label>
                                    <p>
                                      {updateUser && updateUser.dob ?
                                        <>
                                          {moment(updateUser.dob).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </> : "NA"
                                      }
                                    </p>
                                  </Form.Group>
                                </div>
                                <div className={styles.userInfo}>
                                  <Form.Group
                                    className="mb-2"
                                  >
                                    <Form.Label className={styles.formLabel}>
                                      {t("COUNTRY_TEXT")}
                                    </Form.Label>
                                    <p>{updateUser && updateUser.customer_country_name ? updateUser.customer_country_name : "NA"}</p>
                                  </Form.Group>
                                </div>

                                <div className={styles.userInfo}>
                                  <Form.Group
                                    className="mb-2"
                                  >
                                    <Form.Label className={styles.formLabel}>
                                      {t("PROFILE_GENDER")}
                                    </Form.Label>
                                    <p>{updateUser && updateUser.customer_gender && updateUser.customer_gender}</p>
                                  </Form.Group>
                                </div>

                                <div className={styles.userInfo}>
                                  <Form.Group
                                    className="mb-2"
                                  >
                                    <Form.Label className={styles.formLabel}>
                                      {t("PROFILE_HEIGHT")}
                                    </Form.Label>
                                    <p>{updateUser && updateUser.customer_height && (updateUser.customer_height != "" && updateUser.customer_height != 'NaN') ? updateUser.customer_height + " CM" : "NA"}</p>
                                  </Form.Group>
                                </div>

                                <div className={styles.userInfo}>
                                  <Form.Group
                                    className="mb-2"
                                  >
                                    <Form.Label className={styles.formLabel}>
                                      {t("PROFILE_WEIGHT")}
                                    </Form.Label>
                                    <p>{updateUser && updateUser.customer_weight && (updateUser.customer_weight != "" && updateUser.customer_weight != "0") ? updateUser.customer_weight + " KG" : "NA"}</p>
                                  </Form.Group>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </Tab>
                        <Tab eventKey="subscription" title="Subscription">
                          <SubscriptionData />
                        </Tab>

                        {(salePlanVisible && salePlanVisible.value == '2' && purchasePlanList == "") ?
                          "" :
                          <Tab eventKey="sale" title="My Plan">
                            <div className="ManageTopSpace">
                              <PurchaseData />
                            </div>
                          </Tab>
                        }
                      </Tabs>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
export default Profile;

