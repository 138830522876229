//---------------- Header Text----------------------
export const TRANSLATIONS_EN = {
  PLAN_TEXT: "Plan",
  SUBSCRIPTION: "Subscription",
  LOGIN: "Login",
  PAY: "Pay",

  //---------------- Footer Text----------------------
  CONTACT_US: "Contact us",
  TERMS_CONDITION: "Terms And Conditions",
  PRIVACY_POLICY: "Privacy Policy",
  COPYRIGHT: "Copyright",
  ALLRIGHTRESERVED: "3FitStyle. All rights reserved.",
  About_Us: "About Us",

  //---------------- Page not found Text----------------------
  TITTLE_TEXT: "Oops !",
  PAGE_NOT_FOUND: "404 - Page Not Found",
  PAGE_PARA: "Sorry, the page you were looking for could not be found",

  //---------------- ContactUS Text----------------------
  CONTACT_HEADING1: " WE WOULD LOVE TO",
  CONTACT_HEADING2: "HERE FROM YOU.",
  CONTACT_PARA: `Have a question or enquiries about 3Fitstyle? Have a great
     idea or how to improve our app? Or you are having any issues?
     send us a message and we will get back to you as soon as
     possible.`,
  NAME: "Name",
  EMAIL_ADDRESS: " Email Address",
  FEEDBACK_TYPE: " Subject / Feedback Type",
  OPTION1: "General Feedback",
  OPTION2: "Report An Issue",
  OPTION3: "Advertiesment And Promo",
  MESSAGE: " Message / Feedback ",
  SUBMIT_BTN: "Submit",
  NAME_FIELD: "Enter Name",
  EMAIL_FIELD: "Enter Email",
  REQUIRED_FIELD: "Please fill required field",
  MESSAGE_FIELD: "Please enter message.",
  ENTER_EMAIL: "Enter Email Address",
  ENTER_NAME: "Please enter your name.",

  //---------------- Forgot password Text----------------------
  FORGOT_HEADING: "Reset Your password",
  FORGOT_PARA: `  Enter your email to receive instructions on how to reset
  your password.`,
  RESET_BTN: "Reset",
  RETURN_PARA: "Or return to ",
  LOGIN_OPTION: "Log In ",
  UPDATE_PASSWORD: "UPDATE PASSWORD",
  NEW_PASSWORD: "Please enter your new password.",
  ENTER_PASSWORD: "Enter Password",

  //---------------- Signup form text----------------------
  CREATE_ACCOUNT: "CREATE ACCOUNT",
  FITSTYLE: "3FITSTYLE",
  ACCOUNT_TEXT: "Account",
  SIGN_UP_WITH: "Sign up With Facebook",
  SIGN_UP_WITH_GOOGLE: "Sign up With Google",
  OR_TEXT: "OR",
  FULLNAME: "Full Name",
  EMAIL: "Email",
  PASSWORD: "Password",
  CONFIRM_PASSWORD: "Confirm Password",
  SIGNUP_TERMS: "By creating an account, you agree to 3FitStyle",
  PRIVACY_POLICY: "Privacy Policy",
  AND_OR: "and",
  TERMS_OF_USE: "Terms Of Use.",
  CREATE_YOUR: "CREATE YOUR",
  HAVE_AN_ACCOUNT: " Do you have an account?",
  LOG_IN: "Log in",
  GET_THE_APP: "Get the 3Fitstyle App",
  INVALID_PASSWORD: "Please enter correct password",
  INVALID_EMAIL: "Please enter a valid email address.",
  MATCH_PASS: "Password does not match the confirm password.",
  FULLNAME_REQUIRED: "Please enter your full name.",
  EMAIL_REQUIRED: "Please enter your email address.",
  CONFIRM_PASSWORD_REQUIRED: "Please enter confirm password.",
  GENDER_HEADING: "WHAT'S YOUR GENDER? ",
  EMAIL_OR: "Email or Username",
  USERNAME_WRONG: `Usernames can only use letters, numbers, underscores and periods. (You can't start OR end your username with a period.)`,
  EXISTS_EMAIL: "Email already exist",
  SOME_WRONG: "Something went to wrong",
  ALREADY_USERNAME: "username already exist",
  ALREADY_EMAIL: "Email already exit",
  ACCEPT_TERMS: "Please accept our terms and conditions.",
  SIGNUP_CHECK: "Sign up for emails to hear from all latest from 3fitstyle.",
  WELCOME_TEXT: "Welcome To 3fitstyle",
  CREATE_USER_ACCOUNT: "Use these login credentials to access the 3fitstyleapp. There you can create and share your workout with friends and family. You can also enjoy a varity of workouts and plans on the app. ",
  DONE: "Done",
  DONE_SHOW: "DONE",
  PREVIOUS: "Previous",
  OR: "Or",
  FEMALE: "Female",
  MALE: "Male",
  OPTIONAL_TEXT: "(Optional)",
  HEIGHT: "How Tall Are You?",
  WEIGHT: "WHAT'S YOUR WEIGHT?",
  FT: "FT",
  CM: "CM",
  IN: "IN",
  LBS: "LBS",
  KG: "KG",
  ABOUT_ERROR_MESSAGE: "Please select gender",
  USERNAME_LENGTH: "Please enter your username, with a minimum of 3 characters.",
  USERNAME_MAXLENGTH: "Please enter your username, with a maximum of 30 characters.",
  NAME_MAXLENGTH: "Please enter your full name, with a maximum of 30 characters.",
  PASS_MAXLENGTH: "Please enter password, with a maximum of 30 characters.",
  PASS_MINLENGTH: "Please enter password, with a minimum of 5 characters.",

  //---------------- Login form text----------------------
  LOGIN_YOUR: "Login To Your",
  LOGIN_WITH_FACEBOOK: "Login With Facebook",
  SIGN_IN_WITH: "Sign in with Facebook",
  SIGN_IN_WITH_GOOGLE: "Sign in with Google",
  USERNAME: "Please enter username.",
  FORGOT_PASSWORD: "Forgot Password?",
  DONT_HAVE_AN_ACCOUNT: "Don't have an account?",
  CREATE_YOURS: "Create yours now",
  SIGN_USERNAME: "SIGN IN WITH USERNAME OR EMAIL",
  SIGNIN_TERMS: "By logging in, you agree to 3FitStyle's",
  SIGN_IN: "SIGN IN",
  MEMBER: "Not a member?",
  Join_Us: "Join Us",

  //--------------Home page text-------------------
  ABOUT: "About us",
  FAQ: "FAQ",
  TERMS: "Terms & Conditions",
  POLICY: "Privacy policy",
  TRAINER_NAME1: "Gorge Bamfo",
  TRAINER_NAME2: "Daniel Taylor",
  TRAINER_NAME3: "Kevin",
  HEADING: "COME TRAIN WITH US",
  PARA: "We are here to inspire, help and motivate you.",
  HEADING1: "JOIN THE 3FITSTYLE TEAM",
  LONG_PARA: `Welcome to the 3fitstyle app, your trusty fitness companion, whether you prefer sweating it out at home or hitting the gym. This app is incredibly easy to use and comes with friendly personal trainers who are here to ensure you reach your fitness goals. Our main mission is to help you achieve the transformation you desire and embrace a healthier lifestyle. With the 3fitstyle app, you'll find a wide range of workouts and comprehensive fitness plans that even include dietary guidance to cater to all your wellness needs. So why wait? Join us today and kickstart your fitness journey with confidence. Rest assured, we'll be right by your side, supporting you every step of the way to make sure you get exactly the results you're after. Get started now!`,
  HEADING2: "OUR TRAINERS",
  SHORT_PARA: `We are committed to promoting health.
  Thousands of our members work out with us every day. We are here to inspire,
  help and motivate you. Download the app and join the 3FitStyle team today.`,
  SIGN_UP: "Sign Up",
  USERNAME_REQUIRED: "Please enter your username or email address.",
  PASSWORD_REQUIRED: "Please enter password.",
  CREATE_USERNAME: "CREATE USERNAME",
  CREATE_PARA: `Pick a username for your account. You can always change it later.`,
  PROFILE_PIC: "ADD PROFILE PHOTO",
  PROFILE_PARA: " Add a profile photo so that your friends know it's you.",
  NEXT_BUTTON: "Next",

  //---------------Profile text----------
  DATE_OF_BIRTH: "Date Of Birth",
  PROFILE_FULLNAME: "Full Name",
  PROFILE_USERNAME: " Username",
  PROFILE_DOB: " Date Of Birth",
  COUNTRY_TEXT: "Country",
  PROFILE_GENDER: "Gender",
  FEMALE: "Female",
  PROFILE_HEIGHT: "Height",
  PROFILE_WEIGHT: "Weight",
  PROFILE_SUBSCRIPTION: "You don't have any subscription",
  PROFILE_PRIMIUM: "Upgrade to Premium",
  PROFILE_OLD_PASSWORD: "Old Password",
  PROFILE_NEW_PASSWORD: " New password",
  PROFILE_CONFIRM_PASSWORD: "Confirm Password",
  UPDATE_BTN: "Update",
  CANCEL_BTN: "Cancel",
  LOGOUT_BTN: "Logout",
  MONTH: "Month",
  DAY: "Day",
  YEAR: "Year",
  SELECT_COUNTRY: "Please select country",
  NOT_MATCH: "Password does not match the confirm password.",
  OLD_PASS: "Please enter your old password.",
  NEW_PASS: "Please enter new password.",
  CONFIRM_PASS: "Please enter confirm password.",
  UNITS: " Units",
  METRIC: "Metric",
  IMPERIAL: "Imperial",

  //---------- Status Error Text---------------
  SESSION_EXPIRE: "Your Session Expired!",

  //---------------- PlanSection Text----------------------
  PLAN_HEADING: "CHOOSE A PLAN",
  PLAN_PARA: `Get started on your fitness goals with our plans. Build muscles, Tone, Burn some fat and get the best shape ever. Any plan 
  you chose here can be edited according to your goals.
  All plans come with diet as well so you will have your workout plan and diet plan in one place. Just add this to your Plan 
  and log in to the app and enjoy.`,
  LOADMORE_BUTTON: "Load More",
  BUY: "BUY",
  // SALE: "Sales",
  SALE: "Exclusive",
  FITNESS_LEVEL: "Fitness Level",
  GOOD_FOR: "Good For",
  EQUIPMENT: "Equipment",
  OVERVIEW: "Overview",
  // SALE_SUMMARY: "Sales Summary",
  SALE_SUMMARY: "Exclusive Summary",
  PURCHASED: "PURCHASED",
  ADD_PURCHASE_TEXT: "You have not added any plan yet. Click here to explore our plan or go to our app.",
  ADD_PLAN_BTN: " ADD PLAN",
  FREE: "Free",
  EXCLUSIVE: "Exclusive",
  PURCHASE_TEXT: "PURCHASED",

  //---------------Subscription detail page text----------
  SUBSCRIPTION_DETAIL_SUMMARY: "Subscription Details",
  PRICE_TEXT: "Price",
  TOTAL_PRICE: "Total",
  SUBSCRIPTION_NOTE: " Note:",
  SUBSCRIPTION_NOTE_PARA: `After payment, you must log in with your username and password
  from your ios or Android device to access everything on the
  app. This page is only for making payments for either
  subscription or exclusive content on the Doviesfitness app.
  You cannot view any other content from here. All payments are
  processed by paypal. You can pay with your Visa, Debit, or
  Mastercard through paypal , even if you don't have a paypal
  account.`,
  POWERED_BY: "Powered by",
  SUBSCRIBE: "Subscribe",
  STARTS: "Starts on",
  RENEWS: "Renews until you cancel",
  START: "Start",
  THEN: "Then",
  PAYMENT: "One time payment",
  LIFETIME: "for a lifetime",
  FOR_EACH: "for each",
  AFTER_TRIAL: "after the trial period",
  EACH: "each",
  FOR: "for a",
  TRIAL: "trial",

  //---------------Subscription data popup page text----------
  PLAN_TYPE: "Plan Type",
  PURCHASE_DATE: "Purchase Date",
  END_DURATION: "Ends In",
  NEXT_BILL_DATE: "Next Bill Date",
  NEXT_BILLING_AMOUNT: "Next Billing Amount",
  STOP_RECURRING: "Subscription Cancelled",
  CANCEL_SUBSCRIPTION_BUTTON: "Cancel Subscription",
  PURCHASED_DATE: "Purchased Date",
  SUBSCRPTION_EXPIRE: "Subscription Duration",
  SUBSCRIPTION_IN: "Subscription Expires In",
  PREMINUM: "3Fitstyle Premium",
  CANCEL_TEXT: `If you cancel now, you can still access your subscription until `,
  SUBSCRIPTION_CANCEL: "Subscription Canceled",
};






