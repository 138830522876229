import facebook from "../../assets/Images/Facebook_Ico.svg";
import instagram from "../../assets/Images/Instagram_Ico.svg";
import twitter from "../../assets/Images/Twitter_Section.svg";

const SocialLinks = [
    {
        id: "1",
        link: process.env.REACT_APP_FACEBOOK_LINKS,
        image: `${facebook}`,
    },
    {
        id: "2",
        link: process.env.REACT_APP_INSTRAGRAM_LINKS,
        image: `${instagram}`,
    },
    {
        id: "3",
        link: process.env.REACT_APP_TWITTER_LINKS,
        image: `${twitter}`,
    },
    // {
    //     id: "4",
    //     link: "/",
    //     image: `${icon}`,
    // },
];
export default SocialLinks;

