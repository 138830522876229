import styles from "../HomePage/HomePage.module.css";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import bg1 from "../../assets/Images/bg1.jpg";
import bg2 from "../../assets/Images/bg2.jpg";
import { useEffect, useState } from "react";
import HeaderSection from "../HeaderSection/HeaderSection";
import { Col, Container, Row } from "react-bootstrap";
import playstore from "../../assets/Images/playstore.png";
import appstore from "../../assets/Images/appstore.png";
import TrainerData from "./TrainerData";
import SublyApi from "../../helpers/Api";
import HomePageSlider from "../../commonComponents/HomePageSlider/HomePageSlider";
import { HOME_IMAGE } from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { setBgImages } from "../../store/slices/UserSlice";
import { STATUS_CODES } from "../../utils/StatusCode";
import $ from "jquery";

// -------------function for home page-----------
function HomePage() {
  const { t } = useTranslation();
  const [packageList, setPackageList] = useState("");
  const [backgroundImage, setBackgroundImage] = useState();
  const [bgIndex, setBgIndex] = useState(0);
  const dispatch = useDispatch();
  const { backgroundImageRes } = useSelector((state) => state.user);

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  //---------function for get home image slider api calling---------- 
  useEffect(() => {
    async function imageSlider() {
      dispatch(setBgImages(HOME_IMAGE.IMAGE_TYPE)).then((responsejson) => {
        const response = responsejson.payload;
        if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
          setBackgroundImage(response.settings.data[0].webImage)
        }
      })
    }
    imageSlider();
  }, []);

  //---------function for home background image change--------- 
  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundImage((previousImage) => {
        if (backgroundImageRes.length > 0) {
          let lastImgIndex = backgroundImageRes.length - 1;
          if (lastImgIndex == bgIndex) {
            setBgIndex(0);
            const webImage = backgroundImageRes[0].webImage;
            return webImage;
          } else {
            const newBgIndex = bgIndex + 1;
            setBgIndex(newBgIndex);
            const webImage = backgroundImageRes[newBgIndex].webImage;
            return webImage;
          }
        }
        else {
          setBackgroundImage(bg1)
        }
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [bgIndex]);

  //---------function for subscription api calling---------- 
  useEffect(() => {
    async function subscriptionPackageList() {
      const details = await SublyApi.getPackageList();
      details && setPackageList(details.data);
    }
    subscriptionPackageList();
  }, []);

  const bgDefaultCss = {
    backgroundSize: "cover",
    width: "100%",
    height: "100vh",
    backgroundPositionX: "center",
    backgroundColor: "transparent",
    transition: "background 0.3s ease",
    // backgroundPosition:"center",
  };
  const bgCss = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    width: "100%",
    height: "100vh",
    backgroundPositionX: "center",
    backgroundColor: "transparent",
    transition: "background 0.3s ease",
    // backgroundPosition:"center",
  };

  return (
    <>
      <HeaderSection />
      <div className="main">
        <div
          className={styles.headerSection}
          // style={{
          //   backgroundImage: `url(${backgroundImage})`,
          //   backgroundSize: "cover",
          //   width: "100%",
          //   height: "100vh",
          //   backgroundPositionX: "center",
          // }}
          style={backgroundImage ? bgCss : bgDefaultCss}
        >
          <div className={styles.headingText}>
            <h1>{t("HEADING")}</h1>
            <h2>{t("PARA")}</h2>

            <div className={styles.loginBtn}>
              <div className={styles.StartUp}>
                <NavLink to="/login">{t("LOGIN")}</NavLink>
              </div>
              <div className={styles.StartUp1}>
                <NavLink to="/sign-up">{t("SIGN_UP")}</NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.textSection}>
          <Container>
            <div className={styles.middileSection}>
              <h2 className={styles.headSection}>{t("HEADING1")}</h2>
              <p>{t("LONG_PARA")}</p>
            </div>
          </Container>
        </div>

        <div className={styles.trainerSection}>
          <Container className="containerMatch">
            <h2 className={styles.headSection}>{t("HEADING2")}</h2>
            <div className={styles.imageSection}>
              <HomePageSlider packageList={packageList} />
            </div>
          </Container>

          <Container>
            <div className={styles.middileSection}>
              <p>{t("SHORT_PARA")}</p>
            </div>
          </Container>

          <Container className="containerMatch">
            <Row>
              {TrainerData.TrainingImg.map((item, index) => (
                <>
                  <Col lg={12}>
                    <div className={styles.trainingImg} key={index}>
                      <img src={item.image} alt="trainer-image" />
                    </div>
                  </Col>
                </>
              ))}
            </Row>
          </Container>
          <Container>
            <div className={styles.socialSection}>
              <a href={process.env.REACT_APP_APP_STORE_LINKS} target="_blank">
                <img src={appstore} alt="app-store" />
              </a>
              <a href={process.env.REACT_APP_PLAY_STORE_LINKS} target="_blank">
                <img src={playstore} alt="play-store" />
              </a>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
export default HomePage;
