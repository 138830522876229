import { Col, Container, Row } from "react-bootstrap";
import styles from "../../../appComponents/Plan/Plan.module.css";
import SubHeader from "../../HeaderSection/SubHeader";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { STATUS_CODES } from "../../../utils/StatusCode";
import SublyApi from "../../../helpers/Api";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../utils/Loader/Loader";
import { ACCESS_LEVEL, PLAN, SESION_TRANSACTION_TYPE } from "../../../utils/Constants";
import $ from "jquery";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import { Toast } from "../../../utils/Toaster";


//-----function for plan image details---------- 
function FitnessPlanDetail() {
    const { t } = useTranslation();
    const [planDetails, setPlanDetails] = useState("");
    const { id, type } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentUser, userToken } = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(false);
    const [showPlanDetails, setShowPlanDetails] = useState("");

    // --------function for open component top of the page ----------
    useEffect(() => {
        $("document").ready(function () {
            $(window).scrollTop(0);
        });
    }, []);


    // ---------function for workout plan details api calling----------
    useEffect(() => {
        async function getPlanDetails() {
            setIsLoading(true);
            const details = await SublyApi.workoutPlanDetails(
                id,
                type,
                currentUser && currentUser.customer_id ? currentUser.customer_id : ""
            );
            setIsLoading(false);
            if (details && details.settings && details.settings.success && details.settings.success == STATUS_CODES.SUCCESS_API
            ) {
                setPlanDetails(details && details.settings.data);
            } else {
                setShowPlanDetails(details && details.settings.data);
            }
        }
        getPlanDetails();
    }, []);


    // ---------function for check session & plan api calling----------
    async function ManageSessionExpire() {
        const requestData = new FormData();
        requestData.append("transaction_type", SESION_TRANSACTION_TYPE.SALE);
        requestData.append("plan_id", planDetails && planDetails.workout_id);
        requestData.append("plan_type", planDetails && planDetails.plan_type == "workout_plan"
            ? PLAN.WORKOUT_PLAN
            : PLAN.STREAM_WORKOUT_PLAN);
        await SublyApi.sessionManage(requestData, userToken).then((response) => {
            if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
                navigate("/exclusive/paypal", {
                    state: {
                        planSection: true,
                        planDetails: planDetails,
                    },
                })
            }
            else if (response && response.settings && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
                Toast.fire({
                    icon: "error",
                    title: t("SESSION_EXPIRE"),
                });
                dispatch(userLogoutClear());
                navigate("/login");
            }
        }
        );
    }

    return (
        <div className="main">
            <>
                {isLoading === true ? <Loader /> : ""}
                <SubHeader />
                {planDetails ?
                    <div className="widthManage">
                        <div className={styles.planDetailSection}>
                            <Container className="planContainer">
                                <Row>
                                    <Col lg={6} md={12} sm={12} className="containerHide">
                                        <div className={styles.planDetailImage}>
                                            <img src={planDetails.workout_image} alt="plan-image" />

                                            {planDetails.acess_level == ACCESS_LEVEL.LOCK ? (
                                                <>
                                                    {planDetails &&
                                                        planDetails.amount == "0" ?
                                                        <div className={styles.lockIconDetail}>
                                                            <p>{t("FREE")}</p>
                                                        </div> : <div className={styles.lockIconDetail}>
                                                            <span>{planDetails.currency_symbol}{planDetails.amount}</span>
                                                        </div>}</>
                                            ) : (
                                                (currentUser && Number(currentUser.is_subscribed) == 1 && planDetails && planDetails.plan_level && planDetails.plan_level.toLocaleLowerCase() == "paid") ?
                                                    "" :
                                                    <div className={styles.lockIconDetail}>
                                                        <p>{t("PURCHASE_TEXT")}</p>
                                                    </div>
                                            )}

                                            {planDetails && planDetails.show_in_app && planDetails.show_in_app.toLocaleLowerCase() == "no" ? "" : (
                                                planDetails.workout_name && (
                                                    <div className={styles.titleSet}>
                                                        <h2>{planDetails.workout_name}</h2>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </Col>
                                    <Col lg={6} md={12} sm={12}>
                                        <div className={styles.planDetailRight}>
                                            {planDetails.workout_level && (
                                                <>
                                                    <div className={styles.detailsOptions}>
                                                        <h2>{planDetails.workout_level}</h2>
                                                        <p>{t("FITNESS_LEVEL")}</p>
                                                    </div>
                                                    {(planDetails.good_for_name == "" && planDetails.equipment_name == "" && planDetails.workout_description == "") ?
                                                        "" :
                                                        <div className={styles.borderSet}>
                                                        </div>}
                                                </>
                                            )}

                                            {planDetails.good_for_name && (
                                                <>
                                                    <div className={styles.detailsOptions}>
                                                        <h2>{t("GOOD_FOR")}</h2>
                                                        <p>{planDetails.good_for_name}</p>
                                                    </div>
                                                    {(planDetails.equipment_name == "" && planDetails.workout_description == "") ?
                                                        "" :
                                                        <div className={styles.borderSet}>
                                                        </div>}
                                                </>
                                            )}

                                            {planDetails.equipment_name && (
                                                <>
                                                    <div className={styles.detailsOptions}>
                                                        <h2>{t("EQUIPMENT")}</h2>
                                                        <p>{planDetails.equipment_name}</p>
                                                    </div>
                                                    {planDetails.workout_description == "" ?
                                                        "" :
                                                        <div className={styles.borderSet}>
                                                        </div>
                                                    }
                                                </>
                                            )}

                                            {planDetails.workout_description && (
                                                <div
                                                    className={`${styles.detailsOptions} ${styles.endOption}`}
                                                >
                                                    <h2>{t("OVERVIEW")}</h2>
                                                    <p>{planDetails.workout_description}</p>
                                                </div>
                                            )}

                                            {planDetails &&
                                                planDetails.amount == "0" ?
                                                "" :
                                                <div className={styles.planBuyBtn}>
                                                    {Object.keys(currentUser).length == 0 ? (
                                                        <button
                                                            onClick={() =>
                                                                navigate("/login", {
                                                                    state: {
                                                                        planSection: true,
                                                                        planDetails: planDetails,
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            {t("BUY")}
                                                        </button>
                                                    ) : (
                                                        <button
                                                            onClick={() => {
                                                                ManageSessionExpire();
                                                                // navigate("/exclusive/paypal", {
                                                                //   state: {
                                                                //     planSection: true,
                                                                //     planDetails: planDetails,
                                                                //   },
                                                                // })
                                                            }}
                                                        >
                                                            {t("BUY")}
                                                        </button>
                                                    )}
                                                </div>}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div> : ""}
            </>
            {
                showPlanDetails && navigate("/profile/3")
            }
        </div>
    );
}
export default FitnessPlanDetail;