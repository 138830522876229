import { Container, Row, Col, Form } from "react-bootstrap";
import styles from "../Profile/EditProfile.module.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate } from "react-router-dom";
import UserProfile from "./UserProfile";
import { CM, FT, LBS, IN, KG } from "../../commonComponents/UserDummyData";
import ReactFlagsSelect from "react-flags-select";
import { useEffect, useRef, useState } from "react";
import "../../assets/Styles/Common.css";
import { BiFemale, BiMale } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { customerDeatail, setCurrentUnit, updateDetails, userLogoutClear } from "../../store/slices/UserSlice";
import { Toast } from "../../utils/Toaster";
import UpdatePassword from "./UpdatePassword";
import { heightFtInToCm, weightLbsToKg } from "../../utils/ConvertHeight";
import { STATUS_CODES } from "../../utils/StatusCode";
import Loader from "../../utils/Loader/Loader";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import HeaderSub from "../HeaderSection/HeaderSub";
import SubscriptionData from "../Subscription/SubscriptionData";
import $ from "jquery";

// --------function for edit profile--------
function EditProfile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const genderRef = useRef(null);
  const [customerData, setCustomerData] = useState("");
  const [showHeightSelect, setShowHeightSelect] = useState("");
  const [showWeightSelect, setShowWeightSelect] = useState("");
  const [selectFt, setSelectFt] = useState("");
  const [selectIn, setSelectIn] = useState("");
  const [selectCm, setSelectCm] = useState("");
  const [selectLbs, setSelectLbs] = useState("");
  const [selectKg, setSelectKg] = useState("");
  const [selected, setSelected] = useState("");
  const [selectedError, setSelectedError] = useState("");
  const [gender, setGender] = useState("");
  const { userToken, currentUnit, updateUser, isLoading } = useSelector((state) => state.user);
  const [selectedDate, setSelectedDate] = useState("");
  const [formatedDate, setFormatedDate] = useState("");

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 100;

  const handleDateChange = (date) => {
    const birthDate = new Date(date);
    const year = birthDate.getFullYear();
    const month = birthDate.getMonth() + 1;
    const day = birthDate.getDate();

    const newDate = year + "-" + month + "-" + day;
    setFormatedDate(newDate);
  };

  // --------function for open component top of the page ----------
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  const passwordKeyPress = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, '')); // this remove from all string
  };

  useEffect(() => {
    if (currentUnit == "Metric") {
      setShowWeightSelect("kg");
      setShowHeightSelect("cm");
    } else {
      setShowWeightSelect("lbs");
      setShowHeightSelect("ft");
    }
  }, []);

  //-----function for unit change---------- 
  const handleUnit = (event) => {
    dispatch(setCurrentUnit(event.target.value));
    if (event.target.value == "Metric") {
      setShowWeightSelect("kg");
      setShowHeightSelect("cm");
      setSelectCm(updateUser && updateUser.customer_height ? Math.trunc(updateUser.customer_height) : "");
      setSelectKg(updateUser && updateUser.customer_weight ? updateUser.customer_weight : "");
    } else {
      setShowWeightSelect("lbs");
      setShowHeightSelect("ft");

      const customerHeightCm = updateUser && updateUser.customer_height;
      setSelectCm(customerHeightCm);

      // Convert height from cm to feet and inches
      const inches = customerHeightCm / 2.54; // Convert cm to inches
      const feet = Math.floor(inches / 12);
      const remainingInches = inches % 12;

      setSelectFt(Math.trunc(feet));
      setSelectIn(Math.trunc(remainingInches));

      let weight = updateUser && updateUser.customer_weight
      setSelectKg(weight);

      // Convert weight from kg to lbs
      const weightInLbs = weight * 2.20462;
      setSelectLbs(Math.round(weightInLbs));
    }
  };

  const handleSelectFt = (event) => {
    setSelectFt(event.target.value);
  };

  const handleSelectIn = (event) => {
    setSelectIn(event.target.value);
  };

  const handleSelectCm = (event) => {
    setSelectCm(event.target.value);
  };

  const handleSelectLbs = (event) => {
    setSelectLbs(event.target.value);
  };
  const handleSelectKg = (event) => {
    setSelectKg(event.target.value);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  function handleCountryError() {
    setSelectedError("");
  }

  function setUserData(updatedUserData){
    if (updatedUserData) {
      setCustomerData(updatedUserData ? updatedUserData : "");
      setValue(
        "fullName", updatedUserData.customer_full_name ? updatedUserData.customer_full_name.charAt(0).toUpperCase()
          + updatedUserData.customer_full_name.slice(1).toLowerCase() : ""
      );
      setValue(
        "userName", updatedUserData.customer_user_name ? updatedUserData.customer_user_name : ""
      );
      setValue(
        "email", updatedUserData.customer_email ? updatedUserData.customer_email : ""
      );
      setSelected(updatedUserData.customer_country_id ? updatedUserData.customer_country_id : "");
      setGender(updatedUserData.customer_gender ? updatedUserData.customer_gender.toLowerCase() : "");

      if (updatedUserData.dob) {
        const date = new Date(updatedUserData.dob)
        setSelectedDate(date);
      } else {
        setSelectedDate("");
      }

      if (currentUnit == "Metric") {
        setSelectCm(updatedUserData.customer_height ? Math.trunc(updatedUserData.customer_height) : "");
      } else {
        const customerHeightCm = updatedUserData.customer_height;
        setSelectCm(customerHeightCm);

        // Convert height from cm to feet and inches
        const inches = customerHeightCm / 2.54; // Convert cm to inches
        const feet = Math.floor(inches / 12);
        const remainingInches = inches % 12;

        setSelectFt(Math.trunc(feet));
        setSelectIn(Math.trunc(remainingInches));
      }
      if (currentUnit == "Metric") {
        setSelectKg(updatedUserData.customer_weight ? updatedUserData.customer_weight : "");
      } else {
        let weight = updatedUserData.customer_weight
        setSelectKg(weight);

        // Convert weight from kg to lbs
        const weightInLbs = weight * 2.20462;
        setSelectLbs(Math.round(weightInLbs));
      }

    }
  }

  //--------function for get user details api calling--------  
  useEffect(() => {
    setUserData(updateUser);
    dispatch(customerDeatail(userToken)).then((responsejson) => {
      const response = responsejson.payload;
      if (response && response.settings && response.settings.success && response.settings.success == STATUS_CODES.SUCCESS_API) {
        setCustomerData(response.data[0] ? response.data[0] : "");
        setUserData(response.data[0]);
      }
      else if (response && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: t("SESSION_EXPIRE"),
        });
        dispatch(userLogoutClear())
        navigate("/login");
      }
    })
  }, [])


  //-----------function for update profile api calling-----------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append("name", formdata ? formdata.fullName.charAt(0).toUpperCase() + formdata.fullName.slice(1).toLowerCase() : "");
    requestData.append("user_name", formdata ? formdata.userName.trim() : "");
    requestData.append("email", formdata ? formdata.email.trim() : "");
    if (selected == "") {
      setSelectedError(t("SELECT_COUNTRY"));
      return false;
    }
    else {
      setSelectedError("");
      requestData.append("country_id", selected);
    }
    requestData.append("gender", gender);


    // conver height into cm
    let cm = selectCm;
    if (showHeightSelect == "ft") {
      cm = heightFtInToCm(selectFt, selectIn);
      setSelectCm(cm);
    }
    requestData.append("height", cm);


    // conver weight into kg
    let kg = selectKg;
    if (showWeightSelect == "lbs") {
      kg = weightLbsToKg(selectLbs);
      setSelectKg(kg);
    }
    requestData.append("weight", kg);

    if (formatedDate == undefined || formatedDate == 'undefined') {
      requestData.append("dob", "");
    } else {
      requestData.append("dob", formatedDate);
    }


    const data = { requestData: requestData, userToken: userToken };
    dispatch(updateDetails(data)).then((responsejson) => {
      const response = responsejson.payload;
      if (response.settings.success == STATUS_CODES.SUCCESS_API) {
        Toast.fire({
          icon: "success",
          title: response.settings.message,
        });
        navigate("/profile");
      } else if (response && response.settings && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: t("SESSION_EXPIRE")
        });
        dispatch(userLogoutClear())
        navigate("/login");
      } else {
        Toast.fire({
          icon: "error",
          title: response && response.settings.message,
        });
      }
    });
  };

  return (
    <>
      <HeaderSub />
      <div className="main">
        {isLoading === true ? <Loader /> : ""}
        <div className={styles.profileSection}>
          <section className="SignUpPage">
            <div className="containerManage">
              <Container>
                <div className={styles.profileBlock}>
                  <Row>
                    <Col md={4}>
                      <UserProfile customerData={customerData} />
                    </Col>
                    <Col md={8}>
                      <div className={styles.topRight}>
                        <Tabs
                          defaultActiveKey="info"
                          id="uncontrolled-tab-example"
                          className="tabSection tabClass"
                        >
                          <Tab eventKey="info" title="Basic Info" className="tabClass">
                            <div className="ManageTopSpace">
                              <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className={styles.FormSection}>
                                  <div className={styles.formBorder}>
                                    <div className="capitalLetter">
                                      <Form.Group className={styles.FormGroup}>
                                        <Form.Label className={styles.formLabel}>
                                          {t("FULLNAME")}
                                        </Form.Label>
                                        <Form.Control
                                          className={styles.FormInput}
                                          placeholder={t("FULLNAME")}
                                          type="text"
                                          {...register("fullName", {
                                            onChange: (e) => handleKeyPress(e),
                                            required: {
                                              value: true,
                                              message: `${t("FULLNAME_REQUIRED")}`,
                                            },
                                            maxLength: {
                                              value: 30,
                                              message: `${t("NAME_MAXLENGTH")}`,
                                            },
                                          })}
                                        />
                                        {errors.fullName && (
                                          <span className="errorMsg">
                                            {errors.fullName.message}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </div>
                                  </div>

                                  <div className={styles.formBorder}>
                                    <Form.Group className={styles.FormGroup}>
                                      <Form.Label className={styles.formLabel}>
                                        {t("PROFILE_USERNAME")}
                                      </Form.Label>
                                      <Form.Control
                                        className={styles.FormInput}
                                        placeholder={t("PROFILE_USERNAME")}
                                        type="text"
                                        {...register("userName", {
                                          onChange: (e) => {
                                            passwordKeyPress(e);
                                          },
                                          required: {
                                            value: true,
                                            message: `${t("USERNAME")}`,
                                          },
                                          minLength: {
                                            value: 3,
                                            message: `${t("USERNAME_LENGTH")}`,
                                          },
                                          maxLength: {
                                            value: 30,
                                            message: `${t("USERNAME_MAXLENGTH")}`,
                                          },
                                          pattern: {
                                            value: /(?!^\d+$)^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/,
                                            message: `${t("USERNAME_WRONG")}`,
                                          },
                                        })}
                                      />
                                      {errors.userName && (
                                        <span className="errorMsg">
                                          {errors.userName.message}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </div>

                                  <div className={styles.formBorder}>
                                    <Form.Group className={styles.FormGroup}>
                                      <Form.Label className={styles.formLabel}>
                                        {t("EMAIL")}
                                      </Form.Label>
                                      <Form.Control
                                        className={styles.FormInput}
                                        placeholder={t("EMAIL_OR")}
                                        type="text"
                                        {...register("email", {
                                          onChange: (e) => {
                                            passwordKeyPress(e);
                                          },
                                          required: {
                                            value: true,
                                            message: `${t("EMAIL_REQUIRED")}`
                                          },
                                          pattern: {
                                            value:
                                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: `${t("INVALID_EMAIL")}`,
                                          },
                                        })}
                                      />
                                      {errors.email && (
                                        <span className="errorMsg">
                                          {errors.email.message}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </div>

                                  <div className={styles.formBorder}>
                                    <Form.Label className={styles.formLabel}>
                                      {t("DATE_OF_BIRTH")}
                                    </Form.Label>
                                    <Form.Group className={styles.FormGroup}>
                                      <div className="datePicker">
                                        <DatePicker
                                          selected={selectedDate}
                                          onChange={(date) => {
                                            handleDateChange(date);
                                            setSelectedDate(date);
                                          }}
                                          dateFormat="dd/MM/yyyy"
                                          placeholderText="Date of birth (Optional)"
                                          showYearDropdown
                                          scrollableYearDropdown
                                          yearDropdownItemNumber={100} // Adjust the number of years to display
                                          minDate={new Date(startYear, 0, 1)} // Set the minimum selectable year
                                          maxDate={new Date()}
                                          changeMonth
                                          showMonthDropdown
                                        />
                                      </div>
                                    </Form.Group>
                                  </div>

                                  <div className={styles.formBorder}>
                                    <Form.Label className={styles.formLabel}>
                                      {t("COUNTRY_TEXT")}
                                    </Form.Label>
                                    <Form.Group className={styles.FormGroup}>
                                      <ReactFlagsSelect
                                        placeholder="Country"
                                        selected={selected}
                                        searchable={true}
                                        searchPlaceholder="Search countries"
                                        onSelect={(code) => { setSelected(code); handleCountryError() }}
                                      />
                                      {selectedError && <span className="errorMsg">{selectedError}</span>}
                                    </Form.Group>
                                  </div>

                                  <div className={styles.formBorder}>
                                    <Form.Label className={styles.formLabel}>
                                      {t("PROFILE_GENDER")}
                                    </Form.Label>
                                    <div className={styles.editProfile}>
                                      <div className=" edit gender middle middleCls">
                                        <label>
                                          <input
                                            ref={genderRef}
                                            type="radio"
                                            name="gender"
                                            value="female"
                                            checked={gender === "female" ? true : false}
                                            onChange={() => setGender("female")}
                                          />
                                          <div className="rad2 box">
                                            <span>
                                              {" "}
                                              <BiFemale /> {t("FEMALE")}
                                            </span>
                                          </div>
                                        </label>
                                        <label>
                                          <input
                                            ref={genderRef}
                                            type="radio"
                                            name="gender"
                                            value="male"
                                            checked={gender === "male" ? true : false}
                                            onChange={() => setGender("male")}
                                          />
                                          <div className="rad1 box">
                                            <span>
                                              <BiMale />
                                              {t("MALE")}
                                            </span>
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className={styles.formBorder}>
                                    <Form.Label className={styles.formLabel}>
                                      {t("UNITS")}
                                    </Form.Label>
                                    <div className={styles.editProfile}>
                                      <div className=" edit gender middle middleCls">
                                        <label>
                                          <input
                                            type="radio"
                                            name="unit"
                                            value="Metric"
                                            checked={currentUnit === 'Metric'}
                                            onChange={handleUnit}
                                          />
                                          <div className="rad2 box">
                                            <span>{t("METRIC")}</span>
                                          </div>
                                        </label>
                                        <label>
                                          <input
                                            type="radio"
                                            name="unit"
                                            value="Imperial"
                                            checked={currentUnit === 'Imperial'}
                                            onChange={handleUnit}
                                          />
                                          <div className="rad1 box">
                                            <span>{t("IMPERIAL")}</span>
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className={styles.formBorder}>
                                    <div className={styles.inputBlock}>
                                      <Form.Label className={styles.formLabel}>
                                        {t("PROFILE_HEIGHT")}
                                      </Form.Label>
                                      <div className="editSelectItem">

                                        {currentUnit == "Metric" ?
                                          <Form.Select
                                            value={selectCm}
                                            onChange={handleSelectCm}
                                            className="FormInput m-10"
                                            aria-label="Default select example"
                                          >
                                            <option key={0} value="0">
                                              {t("CM")}
                                            </option>
                                            {CM.map((cm, ind) => (
                                              <option key={ind + 1} value={cm.value}>
                                                {cm.label}<span> {t("CM")}</span>
                                              </option>
                                            ))}
                                          </Form.Select>
                                          :
                                          <>
                                            <Form.Select
                                              value={selectFt}
                                              onChange={handleSelectFt}
                                              className="FormInput"
                                              aria-label="Default select example"
                                            >
                                              <option key={0} value="0">
                                                {t("FT")}
                                              </option>
                                              {FT.map((ft, ind) => (
                                                <option key={ind + 1} value={ft.value}>
                                                  {ft.label} <span> {t("FT")}</span>
                                                </option>
                                              ))}
                                            </Form.Select>

                                            <Form.Select
                                              value={selectIn}
                                              onChange={handleSelectIn}
                                              className="FormInput"
                                              aria-label="Default select example"
                                            >
                                              <option key={0} value="0">
                                                {t("IN")}
                                              </option>
                                              {IN.map((In, ind) => (
                                                <option key={ind + 1} value={In.value}>
                                                  {In.label}<span> {t("IN")}</span>
                                                </option>
                                              ))}
                                            </Form.Select>
                                          </>
                                        }

                                        <div className="edit middle dFlex">
                                          {currentUnit === 'Metric' ?
                                            <label>
                                              <input
                                                type="radio"
                                                name="height_unit"
                                                value="cm"
                                                checked={currentUnit === 'Metric'}
                                              />
                                              <div className="rad2 box curserRemove">
                                                <span>{t("CM")}</span>
                                              </div>
                                            </label>
                                            : <label>
                                              <input
                                                type="radio"
                                                name="height_unit"
                                                value="ft"
                                                checked={currentUnit === 'Imperial'}
                                              />
                                              <div className="rad1 box curserRemove">
                                                <span>{t("FT")}</span>
                                              </div>
                                            </label>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className={styles.formBorder}>
                                    <div className={styles.inputBlock}>
                                      <Form.Label className={styles.formLabel}>
                                        {t("PROFILE_WEIGHT")}
                                      </Form.Label>
                                      <div className="weightRow">
                                        {currentUnit == "Metric" ?
                                          <Form.Select
                                            value={selectKg}
                                            onChange={handleSelectKg}
                                            className="FormInput"
                                            aria-label="Default select example"
                                          >
                                            <option key={0} value="">
                                              {t("KG")}
                                            </option>
                                            {KG.map((kg, ind) => (
                                              <option key={ind + 1} value={kg.value}>
                                                {kg.label}<span> {t("KG")}</span>
                                              </option>
                                            ))}
                                          </Form.Select>
                                          :
                                          <Form.Select
                                            value={selectLbs}
                                            onChange={handleSelectLbs}
                                            className="FormInput"
                                            aria-label="Default select example"
                                          >
                                            <option key={0} value="">
                                              {t("LBS")}
                                            </option>
                                            {LBS.map((lbs, ind) => (
                                              <option key={ind + 1} value={lbs.value}>
                                                {lbs.label}<span> {t("LBS")}</span>
                                              </option>
                                            ))}
                                          </Form.Select>
                                        }

                                        <div className="edit middle dFlex">
                                          {currentUnit === 'Metric' ?
                                            <label>
                                              <input
                                                type="radio"
                                                name="weight_lbs"
                                                value="kg"
                                                checked={currentUnit === 'Metric'}
                                              />
                                              <div className="rad2 box curserRemove">
                                                <span>{t("KG")}</span>
                                              </div>
                                            </label>
                                            : <label>
                                              <input
                                                type="radio"
                                                name="weight_lbs"
                                                value="lbs"
                                                checked={currentUnit === 'Imperial'}
                                              />
                                              <div className="rad1 box curserRemove">
                                                <span>{t("LBS")}</span>
                                              </div>
                                            </label>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                                <div className={styles.updateBtn}>
                                  <div className={styles.logoutBtn}>
                                    <button>{t("UPDATE_BTN")}</button>
                                  </div>
                                  <div className={styles.cancelBtn}>
                                    <button onClick={() => navigate("/profile")}>{t("CANCEL_BTN")}</button>
                                  </div>
                                </div>
                              </Form>
                            </div>
                          </Tab>

                          <Tab eventKey="subscription" title="Subscription">
                            <SubscriptionData />
                          </Tab>
                          {
                            updateUser && updateUser.customer_social_network_id == "" ?
                              <Tab eventKey="password" title="Change Password">
                                <div className="ManageTopSpace">
                                  <UpdatePassword />
                                </div>
                              </Tab> : ""}
                        </Tabs>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default EditProfile;
