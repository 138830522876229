export const STATUS_CODES = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
    PAGE_NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
    EMAIL_ALREADY_EXISTS: 312,
    SOCIAL_USER_NOT_FOUND: 322,
    INVALID_TOKEN: 401,
    INVALID_PARAM:310,
    SUCCESS_API:1,
  };

