import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import PageNotFound from "../appComponents/PageNotFound/PageNotFound";
import Login from "../authComponent/SignUpLoginPage/LoginPage";
import ContactUs from "../appComponents/ContactUs/ContactUs";
import ForgotPassword from "../authComponent/ForgotPassword/ForgotPassword";
import HomePage from "../appComponents/HomePage/HomePage";
import FooterSection from "../appComponents/FooterSection/FooterSection";
import Profile from "../appComponents/Profile/Profile";
import EditProfile from "../appComponents/Profile/EditProfile";
import SignupFlow from "../authComponent/SignUpLoginPage/SignupFlow";
import { useSelector } from "react-redux";
import ResetPassword from "../authComponent/ForgotPassword/ReserPassword";
import FaqPage from "../appComponents/FaqPage/FaqPage";
import PrivacyPolicy from "../appComponents/Content/PrivacyPolicy";
import AboutUs from "../appComponents/Content/AboutUs";
import TermsCondition from "../appComponents/Content/TermsCondition";
import Plan from "../appComponents/Plan/Plan";
import FitnessPlanDetail from "../appComponents/Plan/FitnessPlanDetail/FitnessPlanDetail";
import Subscription from "../appComponents/Subscription/Subscription";
import SubscriptionDetail from "../appComponents/Subscription/SubscriptionDetail";
import FitnessPlanPaypal from "../appComponents/Plan/FitnessPlanDetail/FitnessPlanPaypal";
import ScrollToTop from "../helpers/ScrollToTop";
import PurchasePlanDetail from "../appComponents/Plan/PurchaseData/PurchaseDetails";

//-------Create a component for manage routing--------
function Routers() {
  const { currentUser, salePlanVisible } = useSelector((state) => state.user);
  const { subscriptionDetail } = useSelector((state) => state.subscriptionList);

  return (
    <Router basename={"/"}>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={Object.keys(currentUser).length == 0 ? <HomePage /> : <Navigate to="/profile" />} />
        <Route exact path="/login" element={Object.keys(currentUser).length == 0 ? <Login /> : <Navigate to="/profile" />} />
        <Route
          exact
          path="/profile/:id?"
          element={
            Object.keys(currentUser).length !== 0 ? (
              <Profile />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          exact
          path="/d/:shar?"
          element={
            <Navigate to="/" />
          }
        />

        <Route exact path="/edit-profile" element={Object.keys(currentUser).length !== 0 ? (<EditProfile />) : (<Navigate to="/login" />)} />
        <Route exact path="/sign-up" element={Object.keys(currentUser).length == 0 ? <SignupFlow /> : <Navigate to="/profile" />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/forgot-password" element={Object.keys(currentUser).length == 0 ? <ForgotPassword /> : <Navigate to="/profile" />} />
        <Route
          exact
          path="/subscription/"
          element={
            Object.keys(currentUser).length !== 0 ? (
              // is_subscribed =1(subscribed) & is_subscribed=0(non subscribed)
              currentUser && currentUser.is_subscribed == "1" ? (
                subscriptionDetail &&
                  subscriptionDetail.data &&
                  subscriptionDetail.data.iStatus == "1" ? (
                  <Navigate to="/profile" />
                ) : (
                  <Subscription />
                )
              ) : (
                <Subscription />
              )
            ) : (
              <Subscription />
            )
          }
        />
        <Route
          exact
          path="/subscription/detail"
          element={
            // iStatus = 1 (subscribed) and iStatus =2 (non subscribed)
            Object.keys(currentUser).length !== 0 ? (
              currentUser && currentUser.is_subscribed == "1" ? (
                subscriptionDetail &&
                  subscriptionDetail.data &&
                  subscriptionDetail.data.iStatus == "1" ? (
                  <Navigate to="/profile/1" />
                ) : (
                  <SubscriptionDetail />
                )
              ) : (
                <SubscriptionDetail />
              )
            ) : (
              <Navigate to="/profile" />
            )
          }
        />
        <Route exact path="/reset-password" element={Object.keys(currentUser).length == 0 ? <ResetPassword /> : <Navigate to="/profile" />} />
        <Route exact path="/faq" element={<FaqPage />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/exclusive" element={salePlanVisible && salePlanVisible.value == "1" ? <Plan /> : (<Navigate to="/" />)} />
        <Route exact path="/exclusive/details/:id/:type" element={<FitnessPlanDetail />} />
        <Route
          exact
          path="/purchase/details/:id/:type/:trasId"
          element={<PurchasePlanDetail />}
        />
        <Route exact path="/exclusive/paypal"
          element={
            Object.keys(currentUser).length != 0 ? (
              <FitnessPlanPaypal />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route exact path="/terms-condition" element={<TermsCondition />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>

      <FooterSection />
    </Router>
  );
}
export default Routers;
