import SignUp from "./SignUpPage";
import About from "./About";
import CreateUser from "./CreateUser";
import SignupDone from "./SignupDone";
import { useEffect, useState } from "react";
import $ from "jquery";

function SignupFlow() {
    const [switchTo, setSwitchTo] = useState(0);
    const [previousValue, setPreviousValue] = useState(false);

    // --------function for open component top of the page ----------
    useEffect(() => {
        $("document").ready(function () {
            $(window).scrollTop(0);
        });
    }, []);

    return (<>
        {switchTo == 0 ?
            <SignUp setSwitchTo={setSwitchTo} previousValue={previousValue} setPreviousValue={setPreviousValue} /> : switchTo == 1 ?
                <About setSwitchTo={setSwitchTo} previousValue={previousValue} setPreviousValue={setPreviousValue} /> : switchTo == 2 ?
                    <CreateUser setSwitchTo={setSwitchTo} setPreviousValue={setPreviousValue} /> : switchTo == 3 ?
                        <SignupDone setSwitchTo={setSwitchTo} /> : switchTo == 4}
    </>)
}

export default SignupFlow;