// import img1 from "../../assets/Images/mobileimg.png";
import img1 from "../../assets/Images/3fitstyle_device_home.png";

const TrainingImg = [
    {
        id: "1",
        image: `${img1}`,
    },

];

export default { TrainingImg };





