
import "./PageNotFound.css";
import { useTranslation } from "react-i18next";

function PageNotFound() {
    const { t } = useTranslation();
    return (
        <>
        <div className="main">
            <section className="notFoundWrapper">
                <h1 className="findError">{t("TITTLE_TEXT")}</h1>
                <h1 className="notFound">{t("PAGE_NOT_FOUND")}</h1>
                <p>{t("PAGE_PARA")}</p>
            </section>
            </div>
        </>
    );
}
export default PageNotFound;