const LBS = []
for (let i = 30; i <= 500; i++) {
    LBS.push({ value: i, label: i })
}

const KG = []
for (let i = 13; i <= 227; i++) {
    KG.push({ value: i, label: i })
}

const FT = []
for (let i = 1; i <= 7; i++) {
    FT.push({ value: i, label: i })
}

const IN = []
for (let i = 1; i <= 11; i++) {
    IN.push({ value: i, label: i })
}

const CM = []
for (let i = 1; i <= 242; i++) {
    CM.push({ value: i, label: i })
}

export { LBS, KG, FT, CM, IN };


